import dayjs from "dayjs";

export const formatResponse = (response, isDarkTheme) => {
  const linkColor = isDarkTheme ? "#ADD8E6" : "#8dc1ef";
  return response
      .replace(
          /###\s(.+?)\n/g,
          "<span style=\"font-size: 1.2em; font-weight: bold;\">$1</span><br>",
      )
      .replace(
          /\*\*(.+?)\*\*/g,
          "<strong>$1</strong>",
      )
      .replace(
          /(https?:\/\/[^\s\)]+?)(?<!\.)\.?(?=\s|$|\))/g,
          // eslint-disable-next-line max-len
          `<a href="$1" target="_blank" rel="noopener noreferrer" style="color: ${linkColor};">$1</a>`,
      )
      .replace(
          /\n/g,
          "<br/>",
      );
};

const getGreetingByTime = () => {
  const currentHour = dayjs().hour();
  const timeOfDay =
      currentHour < 12 ?
          "Morning" :
          currentHour < 18 ?
              "Afternoon" :
              "Evening";

  // eslint-disable-next-line max-len
  return `Good ${timeOfDay}! I'm Rex, the BreachRx Regulation AI. How may I help you with cybersecurity, privacy, and data breach regulations today?`;
};

export const getGreeting = () => ({
  text: getGreetingByTime(),
  sender: "Rex",
  isGreeting: true,
});
