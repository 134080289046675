import React from "react";
import {
  Box, Button, Dialog, DialogContent, DialogTitle, Typography, useMediaQuery,
} from "@mui/material";
import Close from "@mui/icons-material/Close";
import {useOverview} from "./OverviewProvider";
import {useOverviewStyles} from "./useOverviewStyles";
import {smallDeviceWidth} from "../../resources/theme";

export const regScoutOverviewData = {
  regScout: [
    {
      title: "Research",
      content: require("~/public/overviews/1-research.png"),
      description: `Stay current on global cybersecurity, data breach, and privacy
      regulations, including which regulators are most active.`,
    },
    {
      title: "Discover",
      content: require("~/public/overviews/2-discover.png"),
      description: `Know which regulations apply when an incident occurs by actively filtering
      using the details of the incident.`,
    },
    {
      title: "Detail",
      content: require("~/public/overviews/3-detail.png"),
      description: `Discover regulatory details, including ongoing actions and changes, and 
      which are most likely to impact to your organization.`,
    },
  ],
};

const RegScoutOverview = () => {
  const {isOpen, closeOverview, step, previousStep, nextStep} = useOverview();
  const isSmallDevice = useMediaQuery(`(max-device-width: ${smallDeviceWidth})`);
  const {classes} = useOverviewStyles();
  const overviewData = regScoutOverviewData.regScout;
  const stepCount = overviewData.length;

  if (!isOpen) return null;

  const description = (
    <center>
      <Typography variant="body2" fontSize="14">
        {overviewData[step].description}
      </Typography>
    </center>);


  const dialogContent = isSmallDevice ? (<>
    {description}
    <center style={{marginBottom: "16px"}}>
      <img src={overviewData[step].content} style={{maxWidth: 740, width: "100%"}} />
    </center>
  </>) : (<>
    <center style={{marginBottom: "16px"}}>
      <img src={overviewData[step].content} style={{maxWidth: 740, width: "100%"}} />
    </center>
    {description}
  </>);

  const stepCounter = <Typography variant="h6" id="step">{`${step + 1}/${stepCount}`}</Typography>;

  const stepButton = (title, onClick, variant="contained", sx) => (
    <Button
      variant={variant}
      color="primary"
      onClick={onClick}
      id={`${title.toLowerCase()}-overview`}
      sx={sx}
    >
      {title}
    </Button>
  );

  return (
    <Dialog
      open={isOpen}
      fullWidth
      maxWidth="md"
      PaperProps={{sx: {padding: 1}}}
      id="regscout-overview"
    >
      <DialogTitle>
        {overviewData[step].title}
        <Close onClick={closeOverview} id="regscout-overview-close" className={classes.closeIcon} />
      </DialogTitle>
      <DialogContent>
        {dialogContent}
      </DialogContent>
      <Box display="flex" justifyContent="center" mb={2} mt={isSmallDevice ? 1 : undefined}>
        {step > 0 && stepButton("Back", previousStep, "outlined", {marginRight: "0.5em"})}
        {step < stepCount - 1 && stepButton("Next", nextStep)}
        {step + 1 === stepCount && stepButton("Close", closeOverview)}
      </Box>
      <Box display={isSmallDevice ? "none" : "flex"} justifyContent="center" pb={1}>
        {stepCounter}
      </Box>
    </Dialog>
  );
};

export default RegScoutOverview;
