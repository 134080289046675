import React, {useState} from "react";
import {Box, ListItem, ListItemButton, ListItemIcon, Menu, Tooltip} from "@mui/material";
import TuneOutlinedIcon from "@mui/icons-material/TuneOutlined";
import SwitchControls from "../controls/SwitchControls";
import BackArrowListItem from "../../BackArrowListItem";
import {useRegulations} from "../../../pages/regulations/contexts/RegulationsProvider";

const CustomizeIconMenu = () => {
  const {mapOpen, mapInView, setMapInView} = useRegulations();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Box mt={1}>
        <ListItem disablePadding>
          <Tooltip title="Customize View" placement="right">
            <ListItemButton
              onClick={(event) => setAnchorEl(event.currentTarget)}
              sx={(theme) => ({marginLeft: theme.spacing(.375)})}
            >
              <ListItemIcon>
                <TuneOutlinedIcon />
              </ListItemIcon>
            </ListItemButton>
          </Tooltip>
        </ListItem>
        {mapOpen && mapInView === "US" &&
          <BackArrowListItem
            title="Back To World Map"
            onClick={() => setMapInView("world")}
            iconOnly
          />
        }
      </Box>
      <Menu
        id="map-icon-menu"
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={handleClose}
        anchorOrigin={{vertical: "top", horizontal: "right"}}
        transformOrigin={{vertical: "top", horizontal: "left"}}
        PaperProps={{sx: {borderRadius: 0}}}
        MenuListProps={{role: "menu", sx: {paddingBottom: "unset"}}}
      >
        <SwitchControls />
      </Menu>
    </>
  );
};

export default CustomizeIconMenu;
