import {makeStyles} from "tss-react/mui";
import {smallDeviceWidth} from "../../../../resources/theme";

export const useRegulationsStyles = makeStyles()((theme) => ({
  card: {
    minHeight: theme.spacing(44),
    padding: theme.spacing(2),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    cursor: "pointer",
    width: "100%",
  },
  cardSkeleton: {
    minHeight: theme.spacing(44),
    padding: theme.spacing(2),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    cursor: "pointer",
    width: "100%",
  },
  description: {
    "*": {
      marginTop: 0,
      marginBottom: 0,
    },
  },
  filteredCard: {
    opacity: "30%",
  },
  listWrapper: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    gap: theme.spacing(2),
  },
  cardHeader: {
    color: theme.palette.primary.main,
  },
  userFilterButton: {
    "&> .MuiButton-startIcon": {
      marginTop: "-1px",
      [theme.breakpoints.down("sm")]: {
        fontSize: "1rem",
        display: "none",
      },
    },
  },
  viewButton: {
    [`@media (max-device-width: ${smallDeviceWidth})`]: {
      fontSize: "1rem",
    },
  },
}));
