import React, {useEffect, useRef, useState} from "react";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Typography,
} from "@mui/material";
import Close from "@mui/icons-material/Close";
import MessageOutlinedIcon from "@mui/icons-material/MessageOutlined";
import BasicEditor from "../../editor/BasicEditor";
import {useMutation} from "@apollo/client";
import {SEND_FEEDBACK} from "./queries";
import {useRegulations} from "../../../pages/regulations/contexts/RegulationsProvider";
import {isEmpty} from "underscore";
import {useAppControlsStyles} from "../controls/appControlsStyles";
import {useDialogStyles} from "./useDialogStyles";

const GiveFeedback = () => {
  const {sidebarOpen} = useRegulations();
  const {classes: appControlClasses} = useAppControlsStyles({open: sidebarOpen});
  const {classes} = useDialogStyles();
  const [buttonText, setButtonText] = useState("Give Feedback");
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [error, setError] = useState("");
  const editor = useRef();

  const [sendFeedback, sendFeedbackMutation] = useMutation(SEND_FEEDBACK, {
    onCompleted: () => {
      setError("");
      setButtonText("Thanks for Your Feedback");
      setIsDialogOpen(false);
    },
    onError: () => {
      setError("Error submitting feedback, please try again later.");
    },
  });

  const handleSubmit = () => {
    const feedback = editor.current.getText();
    if (isEmpty(feedback)) {
      setError("No feedback provided, please try again.");
      return;
    }
    sendFeedback({variables: {feedback}});
  };

  useEffect(() => {
    if (buttonText === "Thanks for Your Feedback") {
      setTimeout(() => setButtonText("Give Feedback"), 5000);
    }
  }, [buttonText]);

  return (
    <>
      <Tooltip title="Give Feedback" placement="right">
        <ListItemButton
          onClick={() => setIsDialogOpen(true)}
          disabled={buttonText.includes("Thanks")}
          className={appControlClasses.button}
        >
          <ListItemIcon><MessageOutlinedIcon /></ListItemIcon>
          <ListItemText id="feedback">
            <Box className={appControlClasses.controlsItemTextWrapper}>
              <Typography
                variant="body2"
                className={buttonText.includes("Thanks") ? classes.buttonText : undefined}
              >
                {buttonText}
              </Typography>
            </Box>
          </ListItemText>
        </ListItemButton>
      </Tooltip>
      <Dialog
        open={isDialogOpen}
        fullWidth
        maxWidth="md"
        PaperProps={{sx: {padding: 2}}}
        id="feedback-dialog"
      >
        <DialogTitle sx={{paddingTop: 0}}>
          Give Feedback
          <Close
            onClick={() => setIsDialogOpen(false)}
            id="feedback-dialog-close"
            className={classes.closeIcon}
          />
        </DialogTitle>
        <DialogContent>
          <BasicEditor editorRef={editor} onError={setError} />
        </DialogContent>
        <Box pl={3} mt={0.25}>
          <Button
            onClick={handleSubmit}
            disabled={sendFeedbackMutation.loading}
            variant="outlined"
            color="primary"
            id="feedback-submit"
          >
            Submit
          </Button>
          <Button
            onClick={() => setIsDialogOpen(false)}
            variant="outlined"
            color="error"
            sx={{marginLeft: 1}}
          >
            Cancel
          </Button>
        </Box>
        {!isEmpty(error) && !sendFeedbackMutation.loading ?
          <Typography variant="caption" color="error" className={classes.messageCaptions}>
            {error}
          </Typography> : null
        }
        {sendFeedbackMutation.loading ?
          <Typography variant="caption" color="primary" className={classes.messageCaptions}>
            Submitting feedback....
          </Typography> : null
        }
      </Dialog>
    </>
  );
};

export default GiveFeedback;
