import React, {useEffect, useState} from "react";
import {useSearchParams} from "react-router-dom";
import LoginContainer from "./LoginContainer";
import {InputAdornment, TextField, Typography} from "@mui/material";
import LockOutlined from "@mui/icons-material/LockOutlined";
import AccountCircleOutlined from "@mui/icons-material/AccountCircleOutlined";
import * as yup from "yup";
import {resetResolver} from "./yumSchemas";
import {useLoginStyles} from "./LoginStyles";
import {useLogin} from "./LoginProvider";
import {useFormContext} from "react-hook-form";
import {isBoolean} from "underscore";

const ResetPasswordContent = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const formMethods = useFormContext();
  const {isDarkThemeSaved} = useLogin();
  const {classes} = useLoginStyles({isDarkThemeSaved});

  useEffect(() => {
    formMethods.setValue("username", searchParams.get("username"));
    formMethods.setValue("confirmationCode", searchParams.get("confirmationCode"));
    setSearchParams({});
  }, []);

  return <>
    <TextField
      {...formMethods.register("username", {required: true})}
      id="username"
      label="Email"
      variant="outlined"
      size="medium"
      sx={{marginTop: 4}}
      InputLabelProps={{sx: {userSelect: "none"}}}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <AccountCircleOutlined className={classes.adornmentIcon} />
          </InputAdornment>
        ),
        sx: {borderRadius: 0},
      }}
      className={classes.loginTextField}
    />
    <TextField
      {...formMethods.register("confirmationCode", {required: true})}
      id="confirmationCode"
      label="Confirmation Code"
      variant="outlined"
      size="medium"
      sx={{marginTop: 2}}
      InputLabelProps={{sx: {userSelect: "none"}}}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <AccountCircleOutlined className={classes.adornmentIcon} />
          </InputAdornment>
        ),
        sx: {borderRadius: 0},
      }}
      className={classes.loginTextField}
    />
    <TextField
      {...formMethods.register("password", {required: true})}
      type="password"
      id="password"
      label="Password"
      variant="outlined"
      size="medium"
      sx={{marginTop: 4}}
      InputLabelProps={{sx: {color: "rgb(33, 33, 33)", userSelect: "none"}}}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <LockOutlined className={classes.adornmentIcon} />
          </InputAdornment>
        ),
        sx: {borderRadius: 0},
      }}
      className={classes.loginTextField}
    />
    <TextField
      {...formMethods.register("confirmPassword", {required: true})}
      type="password"
      id="confirmPassword"
      label="Confirm Password"
      variant="outlined"
      size="medium"
      sx={{marginTop: 2}}
      InputLabelProps={{sx: {color: "rgb(33, 33, 33)", userSelect: "none"}}}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <LockOutlined className={classes.adornmentIcon} />
          </InputAdornment>
        ),
        sx: {borderRadius: 0},
      }}
      className={classes.loginTextField}
    />
  </>;
};

const ResetPassword = () => {
  const {resetPassword, isDarkThemeSaved} = useLogin();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isSuccess, setIsSuccess] = useState();
  const {classes} = useLoginStyles({isDarkThemeSaved});

  const submit = async (data, formMethods) => {
    const emailSchema = yup.object({username: yup.string().email()});
    const isValidEmail = await emailSchema.isValid(data);
    if (!isValidEmail) {
      formMethods.setError("username", {type: "manual", message: "Invalid email address."});
      return;
    };
    const codeSchema = yup.object({confirmationCode: yup.string().required()});
    const isValidCode = await codeSchema.isValid(data);
    if (!isValidCode) {
      formMethods.setError("username", {type: "manual", message: "Confirmation code required."});
      return;
    };
    setIsSubmitted(true);
    await resetPassword(data, formMethods, setIsSuccess);
  };

  const successMessage = (
    <Typography sx={{marginTop: 2, marginBottom: 2}} className={classes.confirmationText}>
      Password reset successful!  Please log in using your new password.
    </Typography>
  );

  return <LoginContainer
    submit={submit}
    title="Reset Password"
    submitText={isBoolean(isSuccess) ? undefined : "Submit"}
    formResolver={resetResolver}
    disableSubmit={isSubmitted}
    showReturnToLogin={isBoolean(isSuccess)}
  >
    {isSuccess ? successMessage : <ResetPasswordContent />}
  </LoginContainer>;
};

export default ResetPassword;
