import React from "react";
import PropTypes from "prop-types";
import {Container, Grid, List, Typography} from "@mui/material";
import NewsCard from "./newsViews/NewsCard";
import NewsListElement from "./newsViews/NewsListElement";
import FeaturedArticle from "./newsViews/FeaturedArticle";
import {isEmpty, map} from "underscore";

const NewsView = ({
  data,
  classes,
  userPreferredView = "Classic",
  categories,
}) => {
  const CardView = () => (
    <Grid container>
      {map(categories, (category) =>
        !isEmpty(data?.news?.[category]) ?
          <Grid container key={category}>
            <Typography variant="h5" className={classes.categoryHeader}>
              {category}
            </Typography>
            <Grid container>
              {map(data?.news?.[category], (newsItem, index) => (
                <NewsCard news={newsItem} key={index} classes={classes} />
              ))}
            </Grid>
          </Grid> :
          null,
      )}
    </Grid>
  );

  const ListView = () => (
    <Grid container>
      {map(categories, (category) =>
        !isEmpty(data?.news?.[category]) ?
          <Grid container key={category}>
            <Typography variant="h5" className={classes.categoryHeader}>
              {category}
            </Typography>
            <List
              dense={userPreferredView === "Compact"}
              className={classes.newsList}
            >
              {map(data?.news?.[category], (newsItem, index) => (
                <NewsListElement
                  view={userPreferredView}
                  news={newsItem}
                  key={index}
                  classes={classes}
                />
              ))}
            </List>
          </Grid> :
          null,
      )}
    </Grid>
  );

  const NoNews = () => (
    <div className={classes.noNews}>
      <Typography variant="h5" id="no-news">
        No News Found
      </Typography>
    </div>
  );

  if (isEmpty(data.news)) return <NoNews />;

  return (
    <Container id={`news-container-${userPreferredView.toLowerCase()}`}>
      {!isEmpty(data.news.Featured) && (
        <FeaturedArticle
          featuredArticle={data.news.Featured[0]}
          classes={classes}
          view={userPreferredView}
        />
      )}
      {userPreferredView === "Card" ?
        <CardView /> :
        <ListView />}
    </Container>
  );
};

NewsView.propTypes = {
  data: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  userPreferredView: PropTypes.string,
  categories: PropTypes.array.isRequired,
};

export default NewsView;
