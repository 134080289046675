import React from "react";
import PropTypes from "prop-types";
import {Box, Typography} from "@mui/material";
import UpgradeBanner from "../../../components/upgrade/UpgradeBanner";
import {useDetailStyles} from "./styles/detailStyles";

const NotificationBlock = ({title, content, isVisible, needsUpgrade}) => {
  const {classes} = useDetailStyles();

  if (!isVisible && !needsUpgrade) return null;

  const contentLength = title === "Deadline" ? 1 : title === "Conditions" ? 8 : 12;
  const upgradeContent = "Upgrade to a higher plan to see this content. ".repeat(contentLength);

  return (
    <Box className={classes.notificationBlock}>
      <Box p={1} className={classes.notificationBlockTitle}>
        <Typography paddingBottom={1} variant="body1" sx={{color: "text.primary"}}>
          <b>{title}</b>
        </Typography>
      </Box>
      <Box p={1} className={classes.notificationBlockText}>
        {title === "Conditions" && needsUpgrade ? <UpgradeBanner /> : null}
        <Box className={needsUpgrade ? classes.upgradeContainer : undefined}>
          {needsUpgrade ? upgradeContent : content}
        </Box>
      </Box>
    </Box>
  );
};

NotificationBlock.propTypes = {
  title: PropTypes.string,
  content: PropTypes.any,
  isVisible: PropTypes.any,
  needsUpgrade: PropTypes.bool,
};

export default NotificationBlock;
