import {makeStyles} from "tss-react/mui";
import {smallDeviceWidth} from "../../resources/theme";

export const useFabStyles = makeStyles()((theme, {open}) => ({
  main: {
    position: "fixed",
    top: theme.spacing(2),
    [`@media (max-device-width: ${smallDeviceWidth})`]: {
      top: theme.spacing(1),
      marginLeft: theme.spacing(-1),
    },
  },
  platformFilter: {
    position: "fixed",
    top: theme.spacing(18),
    left: theme.spacing(28),
    borderRadius: 0,
    width: "40px",
    height: "45px",
    [theme.breakpoints.down("sm")]: {
      top: theme.spacing(16),
      left: 0,
      width: "30px",
    },
  },
}));
