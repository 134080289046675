import {makeStyles} from "tss-react/mui";

export const useTemplateStyles = makeStyles()((theme) => ({
  closeIcon: {
    color: theme.palette.grey[700],
    cursor: "pointer",
    right: 0,
    position: "absolute",
    marginRight: theme.spacing(2),
  },
  copyAndPrintIcons: {
    cursor: "pointer",
    marginBottom: theme.spacing(0.75),
    verticalAlign: "middle",
  }
}));
