import React from "react";
import {Skeleton} from "@mui/material";
import {map} from "underscore";
import {useRegulationsStyles} from "./styles/Styles";

export const ListSkeleton = () => {
  const {classes, cx} = useRegulationsStyles();

  return map(Array(10), (each, index) => (
    <Skeleton
      variant="rectangular"
      className={cx(classes.card, classes.cardSkeleton)}
      key={index}
    />
  ));
};
