import {makeStyles} from "tss-react/mui";

export const useOverviewStyles = makeStyles()((theme) => ({
  closeIcon: {
    color: theme.palette.grey[700],
    cursor: "pointer",
    right: 0,
    position: "absolute",
    marginRight: theme.spacing(2),
  },
  list: {
    "& div": {
      maxWidth: "100%",
    },
  },
}));
