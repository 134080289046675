import React from "react";
import {Box, Typography, TextField} from "@mui/material";
import {isEmpty, map, findWhere} from "underscore";
import {useRegulations} from "../contexts/RegulationsProvider";

const RecordsCountInput = () => {
  const {currentFilters, setCurrentFilters} = useRegulations();

  const setRecordCount = (jurisdictionName, count) => {
    setCurrentFilters((prev) => {
      const newRecords = [...prev.records];
      const oldRecord = findWhere(newRecords, {value: jurisdictionName});
      oldRecord.count = isEmpty(count) ? 0 : parseInt(count, 10);
      return {
        ...prev,
        records: [
          ...newRecords,
        ],
      };
    });
  };

  if (isEmpty(currentFilters.records)) {
    return <div>No Jurisdiction(s) Selected</div>;
  }
  return (
    <Box>
      <Typography># of records impacted by jurisdiction</Typography>
      <Box pt={2} display="flex" flexDirection="column" gap={2}>
        {map(currentFilters.records, (record, index) => {
          return (
            <TextField
              key={index}
              id={`records-${record.value}`}
              label={record.value}
              variant="outlined"
              value={record.count}
              type="number"
              onChange={(event) => {
                setRecordCount(record.value, event.target.value);
              }}
              InputProps={{inputProps: {min: 0}}}
            />
          );
        })}
      </Box>
    </Box>
  );
};

export default RecordsCountInput;
