import React, {createContext, useContext} from "react";
import PropTypes from "prop-types";
import {isFunction} from "underscore";

const MetricsContext = createContext({
  emitMetric: () => {},
  emitStartStopMetric: () => {},
});

export const MetricsProvider = ({children, metrics}) => {
  const providerValues = {
    emitMetric: isFunction(metrics?.emitMetric) ? metrics.emitMetric : () => {},
    emitStartStopMetric: isFunction(metrics?.emitStartStopMetric) ?
      metrics.emitStartStopMetric : () => {},
  };

  return (
    <MetricsContext.Provider value={providerValues}>
      {children}
    </MetricsContext.Provider>
  );
};

MetricsProvider.propTypes = {
  children: PropTypes.any,
  metrics: PropTypes.object,
};

export const useMetrics = () => {
  return useContext(MetricsContext);
};
