import React from "react";
import {Autocomplete, Chip, TextField} from "@mui/material";
import {useRegulations} from "../contexts/RegulationsProvider";
import {map} from "underscore";

const RegulationTypesInput = () => {
  const {allRegulationTypes, currentFilters, setCurrentFilters} = useRegulations();
  const filterType = "regulationTypes";

  const handleChange = (values) => setCurrentFilters((prev) => ({
    ...prev,
    [filterType]: values,
  }));

  return (
    <Autocomplete
      multiple
      id="regulation-types-filter-input"
      options={allRegulationTypes}
      getOptionLabel={(option) => option.displayValue}
      value={currentFilters[filterType]}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      filterSelectedOptions
      onChange={(_, value) => handleChange(value)}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Types of Regulations"
        />
      )}
      renderTags={(value, getTagProps) =>
        map(value, (option, index) => {
          return (
            <Chip
              key={index}
              variant="outlined"
              label={option.displayValue}
              {...getTagProps({index})}
            />
          );
        })
      }
    />
  );
};

export default RegulationTypesInput;
