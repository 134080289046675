import {makeStyles} from "tss-react/mui";

export const useDialogStyles = makeStyles()((theme) => ({
  buttonText: {
    color: theme.palette.mode === "dark" ? "lightgreen" : "green",
  },
  closeIcon: {
    color: theme.palette.grey[700],
    cursor: "pointer",
    right: 0,
    position: "absolute",
    marginRight: theme.spacing(2),
  },
  messageCaptions: {
    paddingLeft: theme.spacing(3),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(1),
  },
}));
