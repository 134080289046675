import * as yup from "yup";

export const userSchema = yup.object().shape({
  customerName: yup.string()
      .min(2, "Please enter a valid name")
      .matches(/^[^\<\>\`]*$/, "Invalid character"),
  orgFullName: yup.string()
      .min(2, "Please enter a valid organization name")
      .matches(/^[^\<\>\`]*$/, "Invalid character")
      .required("Organization name is required"),
  orgTitle: yup.string()
      .min(2, "Please enter a valid title")
      .matches(/^[^\<\>\`]*$/, "Invalid character")
      .required("Organization title is required"),
});
