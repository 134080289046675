import {gql} from "@apollo/client";

export const CHATBOT_RESPONSE = gql`
mutation chatbotResponse (
  $question: String!,
  $sessionId: String,
) {
  chatbotResponse(
    question: $question,
    sessionId: $sessionId,
  )
}`;
