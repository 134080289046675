import React, {useEffect} from "react";
import {Box, Typography} from "@mui/material";
import BackArrowListItem from "../../components/BackArrowListItem";
import {useNavigate} from "react-router";
import {useMetrics} from "../../components/metrics/MetricsProvider";
import {usePricingStyles} from "./pricingStyles";

const STRIPE_PUBLISHABLE_KEY = process.env.STRIPE_PUBLISHABLE_KEY;
const STRIPE_PRICING_TABLE_ID = process.env.STRIPE_PRICING_TABLE_ID;

const PricingPage = () => {
  const navigate = useNavigate();
  const {emitMetric} = useMetrics();
  const {classes} = usePricingStyles();

  useEffect(() => {
    emitMetric("view pricing - start", {});
    return () => emitMetric("view pricing - complete", {});
  }, []);

  return (
    <Box className={classes.subscriptionWrapper}>
      <Box width="100%" p={4}>
        <Box display="flex">
          <BackArrowListItem onClick={() => navigate("/subscription")} iconOnly />
          <Typography variant="h5" ml={1}>Pricing</Typography>
        </Box>
        <Box width="100%" mt={2}>
          <stripe-pricing-table
            pricing-table-id={STRIPE_PRICING_TABLE_ID}
            publishable-key={STRIPE_PUBLISHABLE_KEY}
          >
          </stripe-pricing-table>
        </Box>
      </Box>
    </Box>
  );
};

export default PricingPage;
