import amplitude from "amplitude-js";
import {version} from "../../../package.json";

const GRAPHQL_API_URL = process.env.GRAPHQL_API_URL ?? "";
const isLocalDev = window.location.host.search(/\blocalhost/gi) >=0 ||
  GRAPHQL_API_URL.search(/\blocalhost/gi) >= 0;
const isProduction = !isLocalDev && GRAPHQL_API_URL.search(/\bdevelopment/gi) < 0;
export const shouldUseMetrics = !isLocalDev;

export const initializeAmplitude = () => {
  if (!shouldUseMetrics) {
    console.info("Local development or unknown environment - disabling anonymized metrics");
    return;
  }

  const amplitudeKey = isProduction ?
    "6494ecdf3eb6a3be0364b6b48300dd2d" :
    "6beb1fa227ba0464a597b1fba86c3adf";
  try {
    const appVersion = version ?? "unknown";
    amplitude.getInstance().init(amplitudeKey, null, {
      includeUtm: true,
      sameSiteCookie: "Strict",
      secureCookie: true,
      saveEvents: true,
      savedMaxCount: 10,
      unsentKey: "anonymized_metrics_unsent",
      unsentIdentifyKey: "anonymized_metrics_unsent_idKey",
      onExitPage: () => amplitude.getInstance().logEvent("regscout - exit"),
    },
    () => {
      amplitude.getInstance().setVersionName(appVersion);
    },
    );
  } catch (err) {
    console.error("Error initializing anonymized metrics");
  }
};

export const getMetricLocation = (location) => location?.pathname?.split("/")?.[1];
