import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
import duration from "dayjs/plugin/duration";
import relativeTime from "dayjs/plugin/relativeTime";
import quarterOfYear from "dayjs/plugin/quarterOfYear";

// marketing tag lines
export const tagline = "Regulatory Research & Response Platform";
export const riskScoreDefinition = `The BreachRx regulatory risk score is a proprietary ranking
  system that evaluates each regulation based on potential liability, recent regulator activity,
  key terms, and other related characteristics.`;

// fixed paths
export const statusPage = "https://breachrx.statuspage.io/";

// dayjs plugin initialization
export const initializeDayJS = () => {
  dayjs.extend(localizedFormat);
  dayjs.extend(duration);
  dayjs.extend(relativeTime);
  dayjs.extend(quarterOfYear);
};
