import React from "react";
import {useSidebarStyles} from "../sidebar/sidebarStyles";

const UpgradePrintMessage = () => {
  const {classes} = useSidebarStyles();
  return (
    <>
      <span className={classes.sidebarLogo} />
      <br />
      To print, please upgrade to a paid subscription via the Manage Subscription page.
    </>
  );
};

export default UpgradePrintMessage;
