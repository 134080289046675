import {makeStyles} from "tss-react/mui";

export const useAppControlsStyles = makeStyles()((theme, {open}) => ({
  controlsItemTextWrapper: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  button: {
    marginLeft: open ? 0 : theme.spacing(.375),
  },
}));

