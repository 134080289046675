import React from "react";
import {map, sortBy} from "underscore";
import {Autocomplete, TextField, Chip} from "@mui/material";
import {useRegulations} from "../contexts/RegulationsProvider";
import PropTypes from "prop-types";

const TagTypeInput = ({tagType}) => {
  const {currentFilters, setCurrentFilters, tagTypeOptions, tagTypeData} = useRegulations();

  const options = sortBy(tagTypeOptions?.[tagType], (item) => item?.displayValue ?? item?.value);
  const displayName = tagTypeData?.[tagType]?.displayName ?? tagType;

  const handleChange = (values) => setCurrentFilters((prev) => ({
    ...prev,
    [tagType]: values,
  }));


  return (

    <Autocomplete
      multiple
      id="regulation-filter-input"
      options={options}
      getOptionLabel={(option) => {
        if (option.value === true) return "Yes";
        return option?.displayValue ?? option.value;
      }}
      value={currentFilters[tagType]}
      isOptionEqualToValue={(option, value) => option.value === value.value}
      filterSelectedOptions
      onChange={(_, value) => handleChange(value)}
      renderInput={(params) => (
        <TextField
          {...params}
          label={displayName}
        />
      )}
      renderTags={(value, getTagProps) =>
        map(value, (option, index) => {
          const value = option.value === true ? "Yes" : option?.displayValue ?? option.value;
          return (
            <Chip
              key={index}
              variant="outlined"
              label={value}
              {...getTagProps({index})}
            />
          );
        })
      }
    />
  );
};

TagTypeInput.propTypes = {
  tagType: PropTypes.string.isRequired,
};

export default TagTypeInput;
