import React from "react";
import PropTypes from "prop-types";
import {Box, Tooltip, Typography, useMediaQuery, useTheme} from "@mui/material";
import {makeStyles} from "tss-react/mui";
import {smallDeviceWidth} from "../../resources/theme";
import {ComposableMap, Geographies, Geography} from "react-simple-maps";
import {matchGeographyObjects} from "./mapUtils";
import US from "~/public/geographies/states-10m.json";
import world from "~/public/geographies/world-110m.json";
import {isEmpty, isFunction, pluck} from "underscore";

export const useMapsStyles = makeStyles()((theme, {scale}) => ({
  mapSVG: {
    height: scale,
    width: scale,
  },
}));

const Map = ({
  geography,
  mapData,
  onMapClick,
  defaultFill,
  highlight,
  list,
  scale = "75%",
  sx,
}) => {
  const isSmallDevice = useMediaQuery(
      `(max-device-width: ${smallDeviceWidth})`,
  );
  const {classes} = useMapsStyles({scale: isSmallDevice ? "100%" : scale});
  const theme = useTheme();
  const boxSX = sx ?? {textAlign: "center"};
  const whichGeography =
    mapData ? mapData : geography === "US" ? US : world;
  const projection = geography === "US" ? "geoAlbersUsa" : undefined;

  return (
    <Box
      ml={geography === "US" ? 0 : "-24px"}
      sx={boxSX}
    >
      <ComposableMap
        height={500}
        projection={projection}
        className={classes.mapSVG}
      >
        <Geographies
          geography={whichGeography}
          id="map"
          aria-label={`${geography} map`}
        >
          {({geographies}) =>
            geographies.map((geo) => {
              const {isMatching, fill} = matchGeographyObjects(
                  geo?.properties,
                  highlight,
                  defaultFill,
                  theme,
              );
              return (
                <Tooltip
                  title={geo.properties.name || geo.properties.NAME}
                  key={geo.rsmKey}
                  placement="right-start"
                  disableFocusListener={!isMatching}
                  disableHoverListener={!isMatching}
                >
                  <Geography
                    geography={geo}
                    fill={fill}
                    stroke={theme.palette.layout.map.stroke}
                    strokeWidth={0.2}
                    id={
                      isMatching ?
                        geo.properties.name || geo.properties.NAME : undefined
                    }
                    aria-label={
                      isMatching ?
                        geo.properties.name || geo.properties.NAME : undefined
                    }
                    style={{
                      default: {outline: "none"},
                      hover: {
                        outline: "none",
                        cursor:
                          isMatching && isFunction(onMapClick) ? "pointer" : (
                            undefined
                          ),
                      },
                      pressed: {outline: "none"},
                    }}
                    onClick={() =>
                      isMatching && isFunction(onMapClick) && onMapClick(geo)
                    }
                  />
                </Tooltip>
              );
            })
          }
        </Geographies>
      </ComposableMap>
      {list && !isEmpty(highlight) && (
        <Typography sx={{textAlign: "center"}}>
          {pluck(highlight, "name").join(", ")}
        </Typography>
      )}
    </Box>
  );
};

Map.propTypes = {
  geography: PropTypes.string,
  mapData: PropTypes.object,
  onMapClick: PropTypes.func,
  defaultFill: PropTypes.string,
  highlight: PropTypes.array,
  list: PropTypes.bool,
  scale: PropTypes.string,
  sx: PropTypes.object,
};

export default Map;
