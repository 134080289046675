import React from "react";
import PropTypes from "prop-types";
import {Box, Divider, Drawer, IconButton} from "@mui/material";
import {useSidebarStyles} from "./sidebarStyles";
import {Link, useLocation} from "react-router-dom";
import ChevronLeftOutlinedIcon from "@mui/icons-material/ChevronLeftOutlined";
import ChevronRightOutlinedIcon from "@mui/icons-material/ChevronRightOutlined";
import AppControls from "./controls/AppControls";
import CustomizeIconMenu from "./menus/CustomizeIconMenu";
import RegScoutOverview from "../overview/RegScoutOverview";
import {useRegulations} from "../../pages/regulations/contexts/RegulationsProvider";

const Sidebar = ({content}) => {
  const {sidebarOpen, setSidebarOpen} = useRegulations();
  const {classes} = useSidebarStyles({open: sidebarOpen});
  const {pathname} = useLocation();
  const onListPage = pathname === "/";

  const topSection = (
    <Box className={classes.sidebarLogoContainer} px={1.5}>
      <Box width={30} />
      <Link
        className={sidebarOpen ? classes.sidebarLogo : classes.sidebarShieldLogo}
        to="/"
        aria-label="BreachRx Regscout logo"
        id="logo"
      />
      <IconButton
        sx={{marginTop: !sidebarOpen ? 1 : 0}}
        onClick={() => setSidebarOpen((isOpen) => !isOpen)}
      >
        {sidebarOpen ?
        <ChevronLeftOutlinedIcon fontSize="inherit" /> :
        <ChevronRightOutlinedIcon fontSize="inherit" />}
      </IconButton>
    </Box>
  );

  return (
    <Drawer
      variant="permanent"
      open={sidebarOpen}
      className={classes.drawer}
    >
      {topSection}
      <Divider />
      {!sidebarOpen && onListPage && <CustomizeIconMenu />}
      <Box flexGrow={1} className={classes.mainWrapper}>
        {content}
      </Box>
      <Divider />
      <AppControls />
      <RegScoutOverview />
    </Drawer>
  );
};

Sidebar.propTypes = {
  content: PropTypes.node,
};

export default Sidebar;
