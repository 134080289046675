import React from "react";
import PropTypes from "prop-types";
import {Box, Button} from "@mui/material";
import {useNavigate} from "react-router";
import {makeStyles} from "tss-react/mui";

export const useUpgradeBannerStyles = makeStyles()((theme, {grayscale}) => ({
  upgradeBanner: {
    backgroundColor: grayscale ? theme.palette.chip.background : theme.palette.primary.main,
    color: grayscale ? undefined : theme.palette.primary.contrastText,
    cursor: "default",
    minWidth: "100%",
    padding: theme.spacing(0.5),
    position: "absolute",
    top: "50%",
    transform: "translateY(-50%)",
    userSelect: "none",
    zIndex: 1000,
  },
  upgradeButton: {
    marginBottom: theme.spacing(0.25),
    marginLeft: theme.spacing(1.5),
    padding: `0px ${theme.spacing(0.5)}`,
    textTransform: "unset",
  },
}));

const UpgradeBanner = ({message, grayscale=false}) => {
  const {classes} = useUpgradeBannerStyles({grayscale});
  const navigate = useNavigate();

  return (
    <Box className={classes.upgradeBanner}>
      <center>
        {message ?? "Gain access to the details you need to meet every requirement"}
        <Button
          onClick={() => navigate("/pricing")}
          variant="contained"
          color="error"
          className={classes.upgradeButton}
        >
          Upgrade Now
        </Button>
      </center>
    </Box>
  );
};

UpgradeBanner.propTypes = {
  message: PropTypes.string,
  grayscale: PropTypes.bool,
};

export default UpgradeBanner;
