import React, {useState} from "react";
import {Fab, Menu, Tooltip} from "@mui/material";
import SwitchControls from "../controls/SwitchControls";
import TravelExploreOutlinedIcon from "@mui/icons-material/TravelExploreOutlined";
import BackArrowPlatform from "../../BackArrowPlatform";
import {makeStyles} from "tss-react/mui";
import {useRegulations} from "../../../pages/regulations/contexts/RegulationsProvider";

export const useMapFilterPlatformStyles = makeStyles()((theme) => ({
  backArrowPlatformButton: {
    position: "fixed",
    top: theme.spacing(24),
    left: theme.spacing(28),
    borderRadius: 0,
    width: "40px",
    height: "45px",
    [theme.breakpoints.down("sm")]: {
      top: theme.spacing(22),
      left: "0px",
      width: "30px",
    },
  },
}));

const MapFilterMenuPlatform = () => {
  const {classes} = useMapFilterPlatformStyles();
  const {mapOpen, mapInView, setMapInView} = useRegulations();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const goBackToWorldMap = () => {
    setMapInView("world");
  };

  return (
    <>
      <Tooltip title="Map View" placement="right">
        <Fab
          color="primary"
          aria-label="map view"
          className={classes.backArrowPlatformButton}
          onClick={(event) => setAnchorEl(event.currentTarget)}
        >
          <TravelExploreOutlinedIcon />
        </Fab>
      </Tooltip>
      <Menu
        id="map-icon-menu"
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={handleClose}
        anchorOrigin={{vertical: "top", horizontal: "right"}}
        transformOrigin={{vertical: "top", horizontal: "left"}}
        PaperProps={{sx: {borderRadius: 0}}}
        MenuListProps={{role: "menu", sx: {paddingBottom: "unset"}}}
      >
        <SwitchControls />
      </Menu>
      {mapOpen && mapInView === "US" && (
        <BackArrowPlatform onClick={goBackToWorldMap} />
      )}
    </>
  );
};

export default MapFilterMenuPlatform;
