import {makeStyles} from "tss-react/mui";

export const useNewsStyles = makeStyles()((theme) => ({
  noNews: {
    width: "100%",
    textAlign: "center",
    marginTop: "20vh",
  },
  viewButton: {
    "&:hover": {
      cursor: "pointer",
      backgroundColor: theme.palette.draggable.accent,
    },
  },
  activeView: {
    border: `1px solid ${theme.palette.primary.main}`,
  },
  cardRoot: {
    width: "100%",
    maxWidth: theme.spacing(40),
    margin: theme.spacing(1),
    backgroundColor: theme.palette.draggable.accent,
  },
  fullWidth: {
    maxWidth: theme.spacing(150),
    backgroundColor: theme.palette.mode === "dark" ? theme.palette.draggable.end : "#f6fcff",
  },
  action: {
    height: "100%",
  },
  cardMedia: {
    height: theme.spacing(25),
    margin: theme.spacing(1),
    backgroundSize: "contain",
  },
  overlay: {
    position: "absolute",
    top: "10px",
    right: "10px",
    fontSize: "0.875rem",
    lineHeight: 1,
    padding: theme.spacing(0.25),
    textTransform: "uppercase",
  },
  featured: {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
    padding: theme.spacing(0.5),
  },
  title: {
    fontWeight: 500,
  },
  feed: {
    fontWeight: 500,
    marginBottom: theme.spacing(1),
  },
  content: {
    maxHeight: theme.spacing(12),
    overflow: "hidden",
  },
  newsList: {
    width: theme.spacing(150),
    maxWidth: theme.spacing(150),
  },
  avatarClassic: {
    width: theme.spacing(8),
    height: theme.spacing(8),
    padding: "10px",
    paddingLeft: "0",
    "& .MuiAvatar-img": {
      objectFit: "contain",
    },
  },
  avatarCompact: {
    width: theme.spacing(5),
    height: theme.spacing(5),
    paddingRight: "10px",
    "& .MuiAvatar-img": {
      objectFit: "contain",
    },
  },
  listText: {
    marginRight: "100px",
  },
  categoryHeader: {
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1),
  },
}));
