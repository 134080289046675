import React, {useState} from "react";
import {ListItemButton, ListItemIcon, ListItemText, Menu, MenuItem} from "@mui/material";
import ArrowUpwardOutlinedIcon from "@mui/icons-material/ArrowUpwardOutlined";
import ArrowDownwardOutlinedIcon from "@mui/icons-material/ArrowDownwardOutlined";
import {useRegulations} from "../../../pages/regulations/contexts/RegulationsProvider";
import {findWhere, map, sortBy} from "underscore";

const RegulationSortMenu = () => {
  const {currentSort, setCurrentSort} = useRegulations();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = !!anchorEl;

  const options = [
    {name: "Last updated", sortString: "updated_desc", order: 5},
    {name: "Oldest first", sortString: "updated_asc", order: 5},
    {
      name: "Effective since",
      icon: <ArrowDownwardOutlinedIcon fontSize="small"/>,
      sortString: "effective_asc",
      order: 3,
    },
    {
      name: "Effective since",
      icon: <ArrowUpwardOutlinedIcon fontSize="small"/>,
      sortString: "effective_desc",
      order: 3,
    },
    {name: "Nearest Deadline", sortString: "deadline_asc", order: 2},
    {name: "Furthest Deadline", sortString: "deadline_desc", order: 2},
    {
      name: "Risk Level",
      icon: <ArrowDownwardOutlinedIcon fontSize="small"/>,
      sortString: "risk_asc",
      order: 4,
    },
    {
      name: "Risk Level",
      icon: <ArrowUpwardOutlinedIcon fontSize="small" />,
      sortString: "risk_desc",
      order: 4,
    },
    {
      name: "Alphabetical",
      icon: <ArrowDownwardOutlinedIcon fontSize="small"/>,
      sortString: "regulator_asc",
      order: 1,
    },
    {
      name: "Alphabetical",
      icon: <ArrowUpwardOutlinedIcon fontSize="small" />,
      sortString: "regulator_desc",
      order: 1,
    },
    {
      name: "Type",
      icon: <ArrowDownwardOutlinedIcon fontSize="small"/>,
      sortString: "type_asc",
      order: 6,
    },
    {
      name: "Type",
      icon: <ArrowUpwardOutlinedIcon fontSize="small" />,
      sortString: "type_desc",
      order: 6,
    },
  ];

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOptionClick = (sortString) => {
    setCurrentSort(sortString);
    setAnchorEl(null);
  };

  const currentSortMatch = findWhere(options, {sortString: currentSort});

  return (
    <>
      <ListItemButton
        id="sort-button"
        aria-haspopup="listbox"
        aria-controls="sort-menu"
        aria-label="Sort Regulations by property"
        aria-expanded={open ? "true" : undefined}
        onClick={(event) => setAnchorEl(event.currentTarget)}
      >
        <ListItemText
          primary="Sort Regulations"
          secondary={
            <span style={{display: "flex"}}>
              {currentSortMatch.name}{currentSortMatch.icon}
            </span>
          }
          sx={{["span svg"]: {marginLeft: "2px", height: "0.9em"}}}
        />
      </ListItemButton>
      <Menu
        id="regulations-sort-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{vertical: "top", horizontal: "left"}}
        MenuListProps={{"aria-labelledby": "regulation-sort-selector", role: "menu"}}
      >
        {map(sortBy(options, "order"), (option, index) => (
          <MenuItem
            key={`${option}-${index}`}
            selected={option.sortString === currentSort}
            onClick={() => handleOptionClick(option.sortString)}
          >
            {option.name}
            {option.icon &&
              <ListItemIcon sx={{["svg"]: {marginLeft: "2px", height: "0.9em"}}}>
                {option.icon}
              </ListItemIcon>
            }
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default RegulationSortMenu;
