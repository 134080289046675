import amplitude from "amplitude-js";
import LogRocket from "logrocket";
import shajs from "sha.js";
import {getMetricLocation, shouldUseMetrics} from "../src/components/metrics/amplitude";
import {isEmpty} from "underscore";

export const initializeMetricsForUser = async (org, user) => {
  if (!shouldUseMetrics || isEmpty(user)) return;
  const encodedUser = shajs("sha256").update(user).digest("hex");
  const encodedOrg = shajs("sha256").update(org).digest("hex");
  amplitude.getInstance().setUserId(encodedUser);
  amplitude.getInstance().setGroup("orgId", encodedOrg);
  LogRocket.getSessionURL((sessionURL) => {
    emitMetric("regscout - enter", {LogRocket: sessionURL});
  });
};

const getTabNameForMetric = (tab) => tab ? (tab?.metric ?? tab?.label)?.toLowerCase() : undefined;

export const emitMetric = (eventName, properties) => {
  if (!shouldUseMetrics || isEmpty(eventName)) return;
  const {location, tab, ...otherProperties} = properties;
  const eventLocation = getMetricLocation(location);
  otherProperties.tab = getTabNameForMetric(tab);
  if (!eventLocation) amplitude.getInstance().logEvent(eventName, otherProperties);
  else amplitude.getInstance().logEvent(`${eventName} ${eventLocation}`, otherProperties);
};

export const emitStartStopMetric = (eventName, properties) => {
  if (!shouldUseMetrics || isEmpty(eventName)) return;
  const {location, ...otherProperties} = properties;
  const eventLocation = getMetricLocation(location);
  if (isEmpty(eventLocation)) return;
  amplitude.getInstance().logEvent(`${eventName} ${eventLocation} - start`, otherProperties);
  return () =>
    amplitude.getInstance().logEvent(`${eventName} ${eventLocation} - complete`, otherProperties);
};
