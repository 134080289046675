import LogRocket from "logrocket";
import setupLogRocketReact from "logrocket-react";
import {
  sanitizeLogRocket, logRocketRequestSanitizer, logRocketResponseSanitizer,
} from "../../../shared/logRocket";

const GRAPHQL_API_URL = process.env.GRAPHQL_API_URL ?? "";
const isLocalDev = window.location.host.search(/\blocalhost/gi) >=0 ||
  GRAPHQL_API_URL.search(/\blocalhost/gi) >= 0;
const isProduction = !isLocalDev && GRAPHQL_API_URL.search(/\bdevelopment/gi) < 0;

export const initializeLogRocket = () => {
  const logRocketAppId = isProduction ?
    "cmqc3w/breachrx-cyber-regscout" : "cmqc3w/breachrx-cyber-regscout-dev";

  if (!isLocalDev) {
    LogRocket.init(logRocketAppId, {
      dom: {
        inputSanitizer: sanitizeLogRocket(isProduction),
        textSanitizer: sanitizeLogRocket(isProduction),
      },
      network: {
        requestSanitizer: sanitizeLogRocket(isProduction) ? logRocketRequestSanitizer : undefined,
        responseSanitizer: sanitizeLogRocket(isProduction) ? logRocketResponseSanitizer : undefined,
      },
    });
    setupLogRocketReact(LogRocket);
  }
};
