import React, {useState} from "react";
import PropTypes from "prop-types";
import {useDetailData} from "../contexts/DetailDataProvider";
import {Box, Dialog, DialogContent, DialogTitle, Tooltip, Typography} from "@mui/material";
import Close from "@mui/icons-material/Close";
import ContentCopyOutlined from "@mui/icons-material/ContentCopyOutlined";
import PrintOutlined from "@mui/icons-material/PrintOutlined";
import PDFIcon from "../../../shared/icons/PDFIcon";
import {DisplayEditor} from "../../../components/editor/DisplayEditor";
import {renderNotificationTemplate} from "../utils/templates";
import {printContent} from "../../../resources/print";
import dayjs from "dayjs";
import {isEmpty, isString} from "underscore";
import {useFileIconStyles} from "../../../shared/icons/iconStyles";
import {useTemplateStyles} from "./styles/templateStyles";

const NotificationTemplate = ({template, type}) => {
  const {data} = useDetailData();
  const {classes} = useFileIconStyles();
  const {classes: templateClasses} = useTemplateStyles();
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  if (isEmpty(data) || isEmpty(template) || isEmpty(type)) return null;

  const handleDialogClose = (event, reason) => {
    if (["backdropClick", "escapeKeyDown"].includes(reason)) return;
    setIsDialogOpen(false);
  };

  const notificationType = type.toLowerCase();
  const notificationTypeTitle = `${type?.[0]}${notificationType.slice(1)}`;
  const regulatorLeader = data?.leader;
  const regulatorLeaderLastName =
    isString(data?.leader?.name) ?
      data.leader.name.match(/(\w+)$/)?.[1] :
      "";
  const regulatorLeaderAddressLine1 =
    !isEmpty(regulatorLeader?.building) ?
      `${regulatorLeader.building}<br />${regulatorLeader?.address}` :
      regulatorLeader?.address;
  const notificationContent = renderNotificationTemplate(template.content, {
    "{{Leader Name}}": regulatorLeader.name,
    "{{Leader Title}}": regulatorLeader.title,
    "{{Leader Address Line 1}}": regulatorLeaderAddressLine1,
    "{{Leader Address Line 2}}": regulatorLeader.address2,
    "{{Date}}": dayjs().format("LL"),
    "{{Leader Last Name}}": regulatorLeaderLastName,
  });

  const copyToClipboard = () => {
    let editorContent = `${notificationContent}`;
    editorContent = editorContent
        .replaceAll("<br>", "\n")
        .replaceAll("<strong>", "")
        .replaceAll("</strong>", "")
        .replaceAll("<p>", "")
        .replaceAll("</p>", "");
    navigator.clipboard.writeText(editorContent);
  };

  return (
    <>
      <Tooltip
        title={`Click here to view ${notificationType} notification template`}
        placement="right"
      >
        <Box
          component="span"
          onClick={() => setIsDialogOpen(true)} style={{cursor: "pointer"}}
        >
          <PDFIcon className={classes.fileIcon} />
        </Box>
      </Tooltip>
      <Dialog
        open={isDialogOpen}
        onClose={handleDialogClose}
        fullWidth
        maxWidth="xl"
      >
        <DialogTitle>
          <Box>
            {notificationTypeTitle} Notification Template
            <Tooltip title="Copy to Clipboard">
              <ContentCopyOutlined
                id="copy-content"
                onClick={copyToClipboard}
                className={templateClasses.copyAndPrintIcons}
                sx={{marginX: 2}}
              />
            </Tooltip>
            <Tooltip title="Print">
              <PrintOutlined
                id="print-content"
                onClick={() => printContent(notificationContent)}
                className={templateClasses.copyAndPrintIcons}
              />
            </Tooltip>
            <Close
              onClick={handleDialogClose}
              id="notification-template-close"
              className={templateClasses.closeIcon}
            />
          </Box>
          <Box sx={{marginTop: -2}}>
            <Typography variant="caption">{data.regulator}</Typography>
          </Box>
        </DialogTitle>
        <DialogContent sx={(theme) => ({paddingTop: `${theme.spacing(1)} !important`})}>
          <DisplayEditor content={notificationContent} shadow />
        </DialogContent>
      </Dialog>
    </>
  );
};

NotificationTemplate.propTypes = {
  template: PropTypes.object,
  type: PropTypes.string,
};

export default NotificationTemplate;
