import React, {useEffect} from "react";
import {Box, Divider, Paper, Typography} from "@mui/material";
import {useLocation, useNavigate} from "react-router-dom";
import BackArrowPlatform from "../../../components/BackArrowPlatform";
import DetailTags from "./DetailTags";
import Map from "../../../shared/maps/Map";
import NewsItem from "./NewsItem";
import Notifications from "./Notifications";
import RecentActions from "./RecentActions";
import TagChips from "./TagChips";
import {useMetrics} from "../../../components/metrics/MetricsProvider";
import {useRegScoutCore} from "../../../resources/RegScoutCoreProvider";
import {useDetailData} from "../contexts/DetailDataProvider";
import {DisplayEditor} from "../../../components/editor/DisplayEditor";
import {generateChips} from "../utils/dataTransformers";
import {isEmptyHTML} from "../../../shared/stringUtils";
import {findGeographyMatch} from "../../../shared/maps/mapUtils";
import dayjs from "dayjs";
import {filter, isEmpty, map, sortBy} from "underscore";
import {useDetailStyles} from "./styles/detailStyles";

const DetailContent = () => {
  const {data} = useDetailData();
  const {classes, cx} = useDetailStyles();
  const location = useLocation();
  const navigate = useNavigate();
  const {emitStartStopMetric} = useMetrics();
  const {isPlatform} = useRegScoutCore();

  if (isEmpty(data)) return null;

  const chips = generateChips(data);
  const isGeographyEmpty = !data?.geography || isEmpty(data?.geography);
  const mapToShow =
    (
      !isGeographyEmpty &&
      findGeographyMatch({NAME: "United States", type: "Country"}, data.geography,
      )
    ) ?
      "US" : undefined;

  useEffect(() => {
    const mapForRegulation = mapToShow === "US" ? "US" : "international";
    return emitStartStopMetric(`view ${mapForRegulation}`, {location});
  }, []);

  const sortedNewsItems = sortBy(data?.newsItems, ({newsItem}) =>
    dayjs(newsItem?.publishedAt),
  ).reverse();
  const breachRxLinks = filter(sortedNewsItems, ({newsItem}) =>
    !newsItem.isDraft && newsItem.publisher === "BreachRx");
  const recentActions = filter(sortedNewsItems, ({newsItem}) =>
    !newsItem.isDraft && newsItem.publisher !== "BreachRx");

  const path = !isPlatform ? "/" : "/reports/research";
  const goBackToRegulations = () => {
    navigate(path, {state: location?.state});
  };

  return (
    <Box className={classes.paperWrapper}>
      {isPlatform && <BackArrowPlatform onClick={goBackToRegulations} />}
      <Paper elevation={4} sx={{borderRadius: 0}}>
        <Box minHeight="100%" padding={3} className={classes.detailWrapper}>
          <Box display="flex" flexDirection="row">
            <Box width="75%" maxWidth="75%" flexDirection="column">
              <Typography variant="caption" className={classes.lastUpdated}>
                {data.legalType} - Last updated by BreachRx on{" "}
                {dayjs(data.updatedAt).format("LL")}
              </Typography>
              <Typography paddingBottom={1} variant="h4">
                {data.regulator}
              </Typography>
              <Box display="flex" gap={1} pb={2}>
                <TagChips chips={chips} />
              </Box>
              <Typography variant="body1">
                Effective Since: {dayjs(data.effectiveAt).format("LL")}
              </Typography>
              <Typography variant="body1">
                Legislation Passed:{" "}
                {dayjs(data.legislationPassedAt).format("LL")}
              </Typography>
              <Box marginTop={2} className={classes.sourceBlock}>
                <Typography className={classes.title} variant="h5" id="source">
                  Source
                </Typography>
                <DisplayEditor content={data.source} />
              </Box>
            </Box>
            <Box marginTop={2} display={isGeographyEmpty ? "none" : undefined}>
              <Map
                geography={mapToShow}
                highlight={data.geography}
                scale={mapToShow !== "US" ? "85%" : undefined}
                sx={{textAlign: "right"}}
              />
            </Box>
          </Box>
          <Divider />
          <Box>
            <Typography className={classes.title} variant="h5" id="description">
              Description
            </Typography>
            <DisplayEditor content={data.descriptionDisplay} />
          </Box>
          {!isEmptyHTML(data.piiDefinition) ?
            <Box className={classes.standoutDetailBlock}>
              <Typography
                className={classes.title}
                variant="h5"
                id="pii-definition"
              >
                PII Definition
              </Typography>
              <DisplayEditor content={data.piiDefinition} />
            </Box> : null}
          {!isEmptyHTML(data.exceptions) ?
            <Box>
              <Typography
                className={classes.title}
                variant="h5"
                id="exceptions"
              >
                Exceptions & Exemptions
              </Typography>
              <DisplayEditor content={data.exceptions} />
            </Box> : null}
          <Box className={classes.redBorderBlock}>
            <Typography
              className={classes.title}
              variant="h5"
              id="fines-impacts"
            >
              Potential Fines & Impacts
            </Typography>
            <DisplayEditor content={data.penalties} />
          </Box>
          {!isEmptyHTML(data.otherRequirements) ?
            <Box>
              <Typography
                className={classes.title}
                variant="h5"
                id="other-requirements"
              >
                Other Requirements
              </Typography>
              <DisplayEditor content={data.otherRequirements} />
            </Box> : null}
          <Divider />
          <Notifications />
          <DetailTags />
          <Divider />
          <RecentActions recentActions={recentActions} />
          <Box>
            <Typography className={classes.title} variant="h5" id="links">
              BreachRx Links
            </Typography>
            <ul className={cx(classes.actionLinks, classes.breachRxLinks)}>
              {!isEmpty(breachRxLinks) ?
                map(breachRxLinks, ({newsItem}, index) => (
                  <NewsItem newsItem={newsItem} key={index} />
                )) : "None"}
            </ul>
          </Box>
        </Box>
      </Paper>
    </Box>
  );
};

export default DetailContent;
