import React, {useEffect} from "react";
import {Box, Typography} from "@mui/material";
import NewsView from "../../shared/news/NewsView";
import {SkeletonNews} from "./components/SkeletonNews";
import {useQuery} from "@apollo/client";
import {GET_NEWSFEED} from "./queries";
import {useMetrics} from "../../components/metrics/MetricsProvider";
import {useNewsStyles} from "../../shared/news/newsStyles";
import {useNewsFeedStyles} from "./newsFeedStyles";

const NewsFeed = () => {
  const {data, loading} = useQuery(GET_NEWSFEED);
  const {emitMetric} = useMetrics();
  const {classes} = useNewsFeedStyles();
  const {classes: sharedNewsClasses} = useNewsStyles();

  useEffect(() => {
    emitMetric("news - start", {});
    return () => emitMetric("news - complete", {});
  }, []);

  if (loading) return <SkeletonNews />;

  const categories = [
    "Cybersecurity",
    "Breaches",
    "Regulations",
    "Privacy",
    "Legal",
  ];

  return (
    <Box className={classes.newsFeedWrapper} id="news-feed">
      <Box p={3} width="100%">
        <Typography variant="h5" mb={2}>News</Typography>
        <NewsView data={data} classes={sharedNewsClasses} categories={categories} />
      </Box>
    </Box>
  );
};

export default NewsFeed;
