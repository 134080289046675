import React from "react";
import PropTypes from "prop-types";
import {Box, Button, Divider, List} from "@mui/material";
import SwitchControls from "../sidebar/controls/SwitchControls";
import RegulationSortMenu from "../sidebar/menus/SortMenu";
import FilterMenuItem from "./FilterMenuItem";
import RecordsCountInput from "../../pages/regulations/components/RecordsCountInput";
import RegulationsInput from "../../pages/regulations/components/RegulationsInput";
import RegulationTypesInput from "../../pages/regulations/components/RegulationTypesInput";
import TagTypeInput from "../../pages/regulations/components/TagTypeInput";
import BackArrowListItem from "../BackArrowListItem";
import {useRegulations} from "../../pages/regulations/contexts/RegulationsProvider";
import {map} from "underscore";

const FilterList = ({fromFloatingFilter=false}) => {
  const {clearFilters, mapOpen, mapInView, setMapInView, tagTypeData} = useRegulations();

  const filterList = [
    {
      tagType: "Geography",
      tooltip: "The countries and/or states where the individuals impacted reside.",
    },
    {
      tagType: "records",
      menuLabel: "Record Impacted by Jurisdiction",
      tooltip: "The number of records that were impacted per jurisdiction.",
      customInput: <RecordsCountInput />,
    },
    {
      tagType: "PersonalInformationTypes",
      menuLabel: <>{"Individual's"} Names plus<br />Personal Information Types</>,
      tooltip: "The data types impacted, including first name or first initial and last name.",
    },
    {
      tagType: "DataAccessedOrAcquired",
      tooltip: "Whether data was improperly accessed or acquired during the course of the incident",
    },
    {
      tagType: "regulationTypes",
      menuLabel: "Types of Regulations",
      tooltip: "Whether the regulation is focused on cybersecurity, privacy, or data breaches",
      customInput: <RegulationTypesInput />,
    },
    {
      tagType: "userInclude",
      menuLabel: "Include Regulations",
      tooltip: "Any regulations that should always be included, regardless of other options chosen",
      customInput: <RegulationsInput include />,
    },
    {
      tagType: "userExclude",
      menuLabel: "Exclude Regulations",
      tooltip: "Any regulations that should always be excluded, regardless of other options chosen",
      customInput: <RegulationsInput />,
    },
  ];

  if (!tagTypeData) {
    return (
      <Box sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
      }}>
        Loading...
      </Box>
    );
  }

  return (
    <List
      component="nav"
      aria-label="Sort Regulations"
    >
      {!fromFloatingFilter && <>
        <SwitchControls />
        {mapOpen && mapInView === "US" &&
          <BackArrowListItem
            title="Back To World Map"
            onClick={() => setMapInView("world")}
            ButtonProps={{sx: {paddingTop: 0}}}
          />
        }
        <Divider />
      </>}
      <RegulationSortMenu />
      {map(filterList, (filter, index) => (
        <FilterMenuItem
          filterType={filter.tagType}
          menuLabel={filter.menuLabel}
          tooltip={filter.tooltip}
          key={index}
        >
          {filter.customInput ?? <TagTypeInput tagType={filter.tagType}/>}
        </FilterMenuItem>
      ))}
      <Button onClick={clearFilters} size="small" sx={{marginLeft: "11px", marginTop: 1}}>
        Clear Filters
      </Button>
    </List>
  );
};

FilterList.propTypes = {
  fromFloatingFilter: PropTypes.bool,
};

export default FilterList;
