import React from "react";
import {map, sortBy} from "underscore";
import {Autocomplete, TextField, Chip} from "@mui/material";
import {useRegulations} from "../contexts/RegulationsProvider";
import PropTypes from "prop-types";

const RegulationsInput = ({include}) => {
  const {allRegulations, currentFilters, setCurrentFilters} = useRegulations();
  const options = sortBy(allRegulations ?? [], "regulator");
  const filterType = include ? "userInclude" : "userExclude";
  const displayName = `${include ? "Include" : "Exclude"} Regulations`;

  const handleChange = (values) => setCurrentFilters((prev) => ({
    ...prev,
    [filterType]: values,
  }));

  return (
    <Autocomplete
      multiple
      id="regulation-filter-input"
      options={options}
      getOptionLabel={(option) => option.regulator}
      value={currentFilters[filterType]}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      filterSelectedOptions
      onChange={(_, value) => handleChange(value)}
      renderInput={(params) => (
        <TextField
          {...params}
          label={displayName}
        />
      )}
      renderTags={(value, getTagProps) =>
        map(value, (option, index) => {
          return (
            <Chip
              key={index}
              variant="outlined"
              label={option.regulator}
              {...getTagProps({index})}
            />
          );
        })
      }
    />
  );
};

RegulationsInput.propTypes = {
  include: PropTypes.bool,
};

export default RegulationsInput;
