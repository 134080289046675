import React from "react";
import {Box, Typography} from "@mui/material";
import {useDetailData} from "../contexts/DetailDataProvider";
import {isEmpty} from "underscore";

const RegulatorLeader = () => {
  const {data} = useDetailData();

  if (isEmpty(data) || isEmpty(data?.leader?.name)) return null;

  const {name, title, office, building, address, address2, alternate, email, phone} = data.leader;
  const nameOrNameAndTitle = !isEmpty(title) ? `${title} ${name}` : name;

  return (
    <Box>
      <Typography sx={{color: "text.primary"}}>{nameOrNameAndTitle}</Typography>
      {!isEmpty(office) && <Typography sx={{color: "text.primary"}}>{office}</Typography>}
      {!isEmpty(building) && <Typography sx={{color: "text.primary"}}>{building}</Typography>}
      {!isEmpty(address) && <Typography sx={{color: "text.primary"}}>{address}</Typography>}
      {!isEmpty(address2) && <Typography sx={{color: "text.primary"}}>{address2}</Typography>}
      {!isEmpty(email) && <Typography sx={{color: "text.primary"}}>{email}</Typography>}
      {!isEmpty(phone) && <Typography sx={{color: "text.primary"}}>{phone}</Typography>}
      {!isEmpty(alternate) && (
        <>
          <br />
          <Typography variant="caption" sx={{color: "text.primary"}}>Secondary:</Typography>
          <Typography sx={{color: "text.primary"}}>{alternate}</Typography>
        </>
      )}
    </Box>
  );
};

export default RegulatorLeader;
