import React from "react";
import PropTypes from "prop-types";
import SunEditor from "suneditor-react";
import {useMediaQuery} from "@mui/material";
import {smallDeviceWidth} from "../../resources/theme";
import {isFunction} from "underscore";
import {useEditorStyles} from "./editorStyles";

const EDITOR_PASTE_MAX_NUM_CHARACTERS = 5000;

const BasicEditor = ({editorRef, onError}) => {
  const {classes} = useEditorStyles();

  const getSunEditorInstance = (sunEditor) => {
    editorRef.current = sunEditor;
  };
  const isSmallDevice = useMediaQuery(`(max-device-width: ${smallDeviceWidth})`);
  const fontSize = isSmallDevice ? "font-size: 22px" : "font-size: 16px";
  const style = `font-family: Roboto, Helvetica, Arial, sans-serif; ${fontSize}; height: auto;`;

  const handlePaste = (event, editorData) => {
    if (editorData.length > EDITOR_PASTE_MAX_NUM_CHARACTERS) {
      if (isFunction(onError)) onError("Content too long to paste!");
      return false;
    }
    return true;
  };

  return (
    <span className={classes.basicEditor}>
      <SunEditor
        autoFocus
        hideToolbar
        setAllPlugins={false}
        getSunEditorInstance={getSunEditorInstance}
        onPaste={handlePaste}
        setDefaultStyle={style}
        setOptions={{
          resizingBar: false,
          addTagsWhitelist: "b|blockquote|br|center|em|i|h1|h2|h3|h4|h5|h6|p|strong|u",
          pasteTagsWhitelist: "b|blockquote|br|center|em|i|h1|h2|h3|h4|h5|h6|p|strong|u",
          attributesWhitelist: {all: "id"},
          plugins: [],
          showPathLabel: false,
        }}
      />
    </span>
  );
};

BasicEditor.propTypes = {
  editorRef: PropTypes.object.isRequired,
  onError: PropTypes.func,
};

export default BasicEditor;
