import React from "react";
import PropTypes from "prop-types";
import {Grid, List} from "@mui/material";
import NewsCard from "./NewsCard";
import NewsListElement from "./NewsListElement";

const FeaturedArticle = ({featuredArticle, classes, view = "Classic"}) => {
  if (view === "Compact") {
    return (
      <Grid container>
        <List dense className={classes.newsList}>
          <NewsListElement
            view={view}
            news={featuredArticle}
            featured
            classes={classes}
          />
        </List>
      </Grid>
    );
  }
  return (
    <NewsCard
      news={featuredArticle}
      featured
      key={0}
      classes={classes}
      fullWidth
    />
  );
};

FeaturedArticle.propTypes = {
  featuredArticle: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  view: PropTypes.string,
};

export default FeaturedArticle;
