import React from "react";
import {useRegScoutCore} from "../../../resources/RegScoutCoreProvider";
import {useDetailData} from "../contexts/DetailDataProvider";
import {Box, Chip, Typography} from "@mui/material";
import UpgradeBanner from "../../../components/upgrade/UpgradeBanner";
import {
  each,
  filter,
  groupBy,
  isEmpty,
  keys,
  map,
  sortBy,
} from "underscore";
import {useDetailStyles} from "./styles/detailStyles";

const DetailTags = () => {
  const {data} = useDetailData();
  const {needsUpgrade} = useRegScoutCore();
  const {classes} = useDetailStyles();

  const tags = data?.tags;
  if (isEmpty(tags)) return null;

  const tagsToDisplay = filter(
      tags,
      ({tag}) => !["Deadline", "RiskScore"].includes(tag.type.name),
  );
  const tagsSortedWithGeographyFirst = sortBy(tagsToDisplay, ({tag}) =>
    tag.type.name === "Geography" ? "1" : tag.type.name,
  );
  const tagsGroupedByType = groupBy(
      tagsSortedWithGeographyFirst,
      ({tag}) => tag.type?.displayName ?? tag.type.name,
  );

  const tagGroupsToDisplay = [];
  each(keys(tagsGroupedByType), (tagType, index) => {
    const tagValues = map(tagsGroupedByType[tagType], ({tag}) => {
      const value = JSON.parse(tag.value);
      return `${value?.country?.name ?? value?.state?.name ?? value}`;
    });
    if (!needsUpgrade || (needsUpgrade && tagType === "Jurisdiction")) {
      tagGroupsToDisplay.push(
          <Box ml={2} mb={1} id={tagType} key={index}>
            <Typography variant="h6">{tagType}</Typography>
            {map(sortBy(tagValues), (value, index) => {
              return (
                <Chip
                  label={value}
                  key={index}
                  sx={{marginRight: 1, marginBottom: 1}}
                />
              );
            })}
          </Box>,
      );
    }
  });

  return (
    <Box>
      <Typography className={classes.title} variant="h5">
        Notification Triggers
      </Typography>
      <Typography variant="caption" display="flex" color="text.main">
        These are the elements that determine if notifications are required by
        this regulation
      </Typography>
      {tagGroupsToDisplay}
      {needsUpgrade && (
        <Box position="relative" p={2} mr={2}>
          <UpgradeBanner grayscale />
        </Box>
      )}
    </Box>
  );
};

export default DetailTags;
