import countryData from "../../../public/geographies/countries_states.json";
import {
  addToRiskScoreColors, riskScoreColorForCount,
} from "../../pages/regulations/utils/dataTransformers";
import {each, filter, find, findWhere, isEmpty, isUndefined, keys} from "underscore";

export const getStateById = (stateId) => {
  for (const country of countryData) {
    const foundState = findWhere(country.states, {id: parseInt(stateId, 10)});
    if (!isEmpty(foundState)) return {countryIsoCode: country.iso2, ...foundState};
  }
};

export const findGeographyMatch = (geographyToMatch, geographiesToFindMatch, returnMultiple) => {
  const matchingFunction = returnMultiple ? filter : find;
  const matchFound = matchingFunction(geographiesToFindMatch, (geography) => {
    if (geography.type === "Country") {
      return (
        geography.name === geographyToMatch.NAME ||
        geography.name === geographyToMatch.NAME_LONG
      );
    };
    if (geography.type === "State") {
      return (
        geography.name === geographyToMatch.name ||
        (geography?.state_code && geographyToMatch?.abbreviation &&
          geography.state_code === geographyToMatch.abbreviation
        )
      );
    };
    return;
  });
  return matchFound;
};

export const matchGeographyObjects = (thisGeo, geographiesToHighlight, defaultFill, theme) => {
  const match = findGeographyMatch(thisGeo, geographiesToHighlight);
  const isMatching = !isEmpty(match);
  const defaultFillColor = defaultFill ?? theme.palette.primary.main;
  const fill = isMatching ?
    (match?.riskScoreColor ? theme.palette?.[match.riskScoreColor]?.main :
      match?.fill ? match.fill : defaultFillColor) :
    theme.palette.grey["400"];

  return {isMatching, fill};
};

const matchHighestRiskColorToGeography = (riskScoreColors, geographyElement, geographies) => {
  const uniqueGeographies = [];
  each(keys(riskScoreColors), (geographyCode) => {
    if (isUndefined(geographyCode)) return;
    const match = find(geographies, (geography) => geography?.[geographyElement] === geographyCode);
    if (match) {
      uniqueGeographies.push({
        ...match,
        riskScoreColor: riskScoreColorForCount(riskScoreColors?.[geographyCode]),
      });
    };
  });
  return uniqueGeographies;
};

export const extractAllGeographyMatches = (geographies) => {
  const stateRiskColors = {};
  const countryRiskColors = {};
  each(geographies, (geography) => {
    if (geography.type === "State") {
      addToRiskScoreColors(stateRiskColors, geography.riskScoreColor, geography.state_code);
    } else {
      addToRiskScoreColors(countryRiskColors, geography.riskScoreColor, geography.isoCode);
    };
  });
  const states = matchHighestRiskColorToGeography(stateRiskColors, "state_code", geographies);
  const countries = matchHighestRiskColorToGeography(countryRiskColors, "isoCode", geographies);
  return [...countries, ...states];
};
