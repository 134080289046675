import React, {useEffect} from "react";
import {Box, Link, Typography} from "@mui/material";
import {NavLink, useLocation} from "react-router-dom";
import {useRegScoutCore} from "../../resources/RegScoutCoreProvider";
import {usePricingStyles} from "./pricingStyles";
import {intersection, isEmpty} from "underscore";

const STRIPE_PROFILE_MANAGEMENT_ID = process.env.STRIPE_PROFILE_MANAGEMENT_ID;

const ManageSubscription = () => {
  const {
    currentPlanForDisplay,
    groups,
    isHighTier,
    refreshSession,
    emitMetric,
  } = useRegScoutCore();
  const location = useLocation();
  const locationIncludesPayment = location.search.includes(
      "?payment=successful",
  );
  const {classes} = usePricingStyles();

  useEffect(() => {
    if (locationIncludesPayment) {
      refreshSession();
      emitMetric("manage subscription - payment successful", {});
    } else {
      emitMetric("manage subscription - start", {});
    }
    return () => emitMetric("manage subscription - exit", {});
  }, []);

  const isFreemiumUser =
    isEmpty(
        intersection(groups, ["Admin", "Professional", "Global", "Enterprise"]),
    ) && !isHighTier;
  const manageLink =
    isFreemiumUser ? "/pricing" : (
      `https://billing.stripe.com/p/login/${STRIPE_PROFILE_MANAGEMENT_ID}`
    );

  const thanksForSubscribing = locationIncludesPayment && (
    <Typography mb={2} p={1} fontStyle="italic">
      Thanks for subscribing to Cyber RegScout&trade;!
    </Typography>
  );

  return (
    <Box className={classes.subscriptionWrapper}>
      <Box width="100%" p={3}>
        <Typography variant="h5" mb={2}>
          Manage Subscription
        </Typography>
        {thanksForSubscribing}
        <Box
          mt={1}
          mx={1}
          p={2}
          sx={(theme) => ({border: theme.palette.border})}
          id="stripe-manage-link"
        >
          <Typography variant="h6" mb={2}>
            Current Subscription: {currentPlanForDisplay}
          </Typography>
          <Link
            component={NavLink}
            variant="body2"
            to={manageLink}
            target="_blank"
          >
            {isFreemiumUser ? "Upgrade Now" : "Manage Subscription"}
          </Link>
        </Box>
        <Box
          mt={2}
          mx={1}
          p={2}
          sx={(theme) => ({border: theme.palette.border})}
          id="pricing-link"
        >
          <Typography variant="h6" mb={2}>
            Plans
          </Typography>
          <Link component={NavLink} variant="body2" to="/pricing">
            Explore Plans & Pricing
          </Link>
        </Box>
      </Box>
    </Box>
  );
};

export default ManageSubscription;
