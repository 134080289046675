import React, {useEffect} from "react";
import PropTypes from "prop-types";
import {FormProvider, useForm} from "react-hook-form";
import {Box, Button, Paper, Typography} from "@mui/material";
import Close from "@mui/icons-material/Close";
import ReturnToLogin from "./ReturnToLogin";
import {useNavigate} from "react-router-dom";
import {useLogin} from "./LoginProvider";
import {useLoginStyles} from "./LoginStyles";
import {reduce, isEmpty, isFunction, isUndefined} from "underscore";

const LoginContainer = ({
  children,
  submit,
  submitText,
  disableSubmit,
  title,
  formResolver,
  successMessage,
  showReturnToLogin=false,
}) => {
  const {isDarkThemeSaved, isSubmitting, refreshError} = useLogin();
  const navigate = useNavigate();
  const {classes} = useLoginStyles({isDarkThemeSaved});
  const formMethods = useForm(({resolver: formResolver}));

  useEffect(() => {
    if (isEmpty(formMethods?.formState?.errors)) return;
    formMethods.reset({}, {keepErrors: true});
  }, [formMethods?.formState?.errors]);

  const errorMessages = reduce(Object.keys(formMethods?.formState?.errors), (memo, errorKey) => {
    const error = formMethods?.formState?.errors[errorKey];
    memo.push(error.message);
    return memo;
  }, []);

  const formSubmittedMessage = (
    formMethods.formState.isSubmitted &&
    isEmpty(formMethods?.formState?.errors)
  ) ?
    <Box sx={{alignContent: "center", maxWidth: 350}}>
      <Typography variant="caption" color="green" align="center" className={classes.loginMessages}>
        {successMessage}
      </Typography>
    </Box> : "";

  const handleSubmit = async (data, formMethods) => {
    if (isFunction(submit)) await submit(data, formMethods);
  };

  const showCloseIcon = submitText !== "Login" && !isUndefined(submitText);

  return <Box className={classes.wrapper}>
    <Box>
      <FormProvider {...formMethods}>
        <form onSubmit={formMethods.handleSubmit((data) => handleSubmit(data, formMethods))}>
          <Paper elevation={10} sx={{borderRadius: 0}}>
            {showCloseIcon &&
              <Box className={classes.closeIconBox}>
                <Close
                  onClick={() => navigate("/")}
                  id="close"
                  className={classes.closeIcon}
                  sx={{
                    color: disableSubmit ? "#80808070" : "#808080",
                    cursor: disableSubmit ? "default" : "pointer",
                    pointerEvents: disableSubmit ? "none" : undefined,
                  }}
                />
              </Box>
            }
            <Box
              className={classes.loginPaper}
              sx={{paddingTop: showCloseIcon ? "unset !important" : undefined}}
            >
              <center>
                <Button disabled className={classes.logo} aria-label="BreachRx" />
                {title ? <Typography id="tagline" variant="h5" className={classes.tagline}>
                  {title}
                </Typography> : null}
              </center>
              {children}
              {!!submitText ? <Button
                type="submit"
                id="submit"
                variant="outlined"
                className={classes.submit}
                disabled={disableSubmit}
                sx={isSubmitting ? {
                  "@keyframes blink": {"from, to": {opacity: 1}, "50%": {opacity: 0}},
                  animation: "blink 1s ease-in infinite",
                } : undefined}
              >
                {submitText}
              </Button> : ""}
              <Box
                display={!errorMessages ? "none" : "flex"}
                justifyContent="center"
                alignItems="center"
                marginTop={1}
              >
                <Typography variant="caption" color="error" className={classes.loginMessages}>
                  {errorMessages.map((message, index) => <div key={index}>{message}</div>)}
                  {!isEmpty(refreshError) &&
                    <>
                      <br />
                      {refreshError?.response?.data?.message ?? refreshError?.message}
                    </>
                  }
                </Typography>
                {formSubmittedMessage}
              </Box>
              {showReturnToLogin && <ReturnToLogin />}
            </Box>
          </Paper>
        </form>
      </FormProvider>
    </Box>
  </Box>; ;
};

LoginContainer.propTypes = {
  children: PropTypes.node.isRequired,
  submit: PropTypes.func,
  submitText: PropTypes.string,
  disableSubmit: PropTypes.bool,
  title: PropTypes.string,
  formResolver: PropTypes.func,
  successMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  showReturnToLogin: PropTypes.bool,
};

export default LoginContainer;
