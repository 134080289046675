import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import {useNavigate, useSearchParams} from "react-router-dom";
import {Box, Typography} from "@mui/material";
import axios from "axios";
import LoginContainer from "./LoginContainer";
import {useLogin} from "./LoginProvider";
import {useLoginStyles} from "./LoginStyles";

const AUTH_ENDPOINT = process.env.AUTH_ENDPOINT;

const ValidateContent = ({message}) => {
  const {isDarkThemeSaved} = useLogin();
  const {classes} = useLoginStyles({isDarkThemeSaved});
  return <Box display="flex" justifyContent="center" alignItems="center" margin={4}>
    <Typography variant="caption" fontSize="1rem" className={classes.verifiedText}>
      {message}
    </Typography>
  </Box>;
};

ValidateContent.propTypes = {
  message: PropTypes.string,
};

const Validate = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [responseMessage, setResponseMessage] = useState();
  const [codeSent, setCodeSent] = useState(false);

  const username = searchParams.get("username");
  const confirmationCode = searchParams.get("confirmationCode");

  useEffect(() => {
    const validate = async () => {
      try {
        const response = await axios.post(`${AUTH_ENDPOINT}/user/validate`, {
          username,
          confirmationCode,
        });
        if (response.status === 200) {
          setResponseMessage(response.data.message);
          setSearchParams({});
        }
      } catch (error) {
        console.log(error);
        setResponseMessage(error?.response?.data?.message ?? error?.message);
      }
    };

    if (confirmationCode && username && !codeSent) {
      validate(confirmationCode);
      setCodeSent(true);
    }

    if (!confirmationCode && !username && !codeSent) {
      setResponseMessage("Please return to the login page to continue.");
    };
  }, [username, confirmationCode]);


  return <LoginContainer
    title="BreachRx RegScout"
    submitText="Login to RegScout"
    submit={() => {
      navigate("/");
    }}
  >
    <ValidateContent message={responseMessage}/>
  </LoginContainer>;
};

export default Validate;
