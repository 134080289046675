import React, {useEffect, useRef} from "react";
import {Box} from "@mui/material";
import {
  DetailDataProvider,
  useDetailData,
} from "../contexts/DetailDataProvider";
import {useRegScoutCore} from "../../../resources/RegScoutCoreProvider";
import DetailSkeleton from "./DetailSkeleton";
import DetailContent from "./DetailContent";
import {useDetailStyles} from "./styles/detailStyles";
import {isEmpty} from "underscore";

const DetailPage = () => {
  const {data, loading} = useDetailData();
  const topOfDetailPage = useRef();
  const {classes} = useDetailStyles();
  const {isPlatform} = useRegScoutCore();

  useEffect(() => {
    !isPlatform ? topOfDetailPage.current?.scrollIntoView({behavior: "instant"}) :
    window.scrollTo(0, 0);
  }, []);

  return (
    <Box display="flex" width={"100%"} ref={topOfDetailPage}>
      <Box className={classes.detailContainer}>
        {loading || !data || isEmpty(data) ?
          <DetailSkeleton /> : <DetailContent />}
      </Box>
    </Box>
  );
};

const Detail = () => (
  <DetailDataProvider>
    <DetailPage />
  </DetailDataProvider>
);

export default Detail;
