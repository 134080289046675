import React, {useEffect, useMemo, useState} from "react";
import {useRegulations} from "../contexts/RegulationsProvider";
import {Dialog, DialogContent, DialogTitle} from "@mui/material";
import Close from "@mui/icons-material/Close";
import Map from "../../../shared/maps/Map";
import List from "./List";
import {useLocation, useNavigate} from "react-router-dom";
import {useMetrics} from "../../../components/metrics/MetricsProvider";
import {useRegScoutCore} from "../../../resources/RegScoutCoreProvider";
import {
  computeRiskChip,
  matchFiltersToGeographies,
} from "../utils/dataTransformers";
import {
  extractAllGeographyMatches,
  findGeographyMatch,
} from "../../../shared/maps/mapUtils";
import {each, find, findWhere, flatten, isArray, map, reject} from "underscore";
import {useOverviewStyles} from "../../../components/overview/useOverviewStyles";

const RegulationMap = () => {
  const {
    areFiltersActive,
    currentFilters,
    mapInView,
    regulationsListData,
    setMapInView,
    useRiskMapOverlay,
  } = useRegulations();
  const location = useLocation();
  const navigate = useNavigate();
  const {emitMetric} = useMetrics();
  const [mapDialog, setMapDialog] = useState(
      location?.state?.map ?? {isOpen: false},
  );
  const {classes: mapDialogClasses} = useOverviewStyles();
  const {isPlatform} = useRegScoutCore();

  useEffect(() => {
    emitMetric(`view ${mapInView} map - start`, {location});
    return () => emitMetric(`view ${mapInView} map - complete`, {location});
  }, [mapInView]);

  const geographiesFromRegulationList = useMemo(() => {
    const unfilteredRegulations = reject(
        regulationsListData,
        (regulation) => regulation?.filtered,
    );
    const geographiesWithRiskScores = map(
        unfilteredRegulations,
        (regulation) => {
          const riskScoreTag = find(
              regulation.tags,
              (item) => item?.tag?.type?.name === "RiskScore",
          );
          const riskScoreColor =
          !useRiskMapOverlay ? "primary" : computeRiskChip(riskScoreTag).color;
          return map(regulation.geography, (geography) => ({
            ...geography,
            riskScoreColor,
          }));
        },
    );
    return flatten(geographiesWithRiskScores);
  }, [regulationsListData, useRiskMapOverlay]);

  const activeGeographies = useMemo(() => {
    const uniqueGeographies = extractAllGeographyMatches(
        geographiesFromRegulationList,
    );
    if (areFiltersActive) {
      return matchFiltersToGeographies(
          geographiesFromRegulationList,
          uniqueGeographies,
      );
    }
    return uniqueGeographies;
  }, [currentFilters, useRiskMapOverlay]);

  const containsUnitedStates = !!findWhere(activeGeographies, {
    name: "United States",
  });

  const onMapClick = (geo) => {
    if (geo.properties.ISO_A3 === "USA" && containsUnitedStates) {
      setMapInView("US");
      return;
    }
    const matchingRegulations = findGeographyMatch(
        geo?.properties, geographiesFromRegulationList, true);
    const unfilteredRegulations = reject(
        regulationsListData, (regulation) => regulation?.filtered);
    const fullMatchingRegulations = [];

    each(matchingRegulations, (eachMatch) => {
      const fullMatch = findWhere(unfilteredRegulations, {
        id: eachMatch.regulationId,
      });
      if (fullMatch) fullMatchingRegulations.push(fullMatch);
    });

    const path = !isPlatform ? "/regulation" : "/reports/research/regulation";

    if (
      isArray(fullMatchingRegulations) &&
      fullMatchingRegulations?.length === 1
    ) {
      navigate(`${path}/${fullMatchingRegulations[0].id}`);
    }
    setMapDialog({isOpen: true, fullMatchingRegulations});
  };

  return (
    <>
      <Map
        geography={mapInView}
        highlight={activeGeographies}
        scale="85%"
        onMapClick={onMapClick}
      />
      <Dialog
        open={mapDialog?.isOpen}
        maxWidth="md"
        PaperProps={{sx: {padding: 1}}}
        id="map-options-dialog"
      >
        <DialogTitle sx={{paddingBottom: 1}}>
          Select from Multiple Matching Regulations
          <Close
            onClick={() => setMapDialog({isOpen: false})}
            id="map-options-dialog-close"
            className={mapDialogClasses.closeIcon}
          />
        </DialogTitle>
        <DialogContent className={mapDialogClasses.list}>
          <List
            customList={mapDialog?.fullMatchingRegulations ?? []}
            customNavigateState={{map: mapDialog}}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default RegulationMap;
