import {makeStyles} from "tss-react/mui";

export const useChatbotStyles = makeStyles()((theme) => ({
  chatbotPaper: {
    borderRadius: 0,
    bottom: "125px",
    display: "flex",
    flexDirection: "column",
    maxHeight: "85%",
    padding: theme.spacing(2),
    position: "fixed",
    right: "50px",
    width: "525px",
    zIndex: 100,
  },
  chatbotWrapper: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: theme.spacing(1),
  },
  chatbotIcon: {
    width: theme.spacing(6),
    height: theme.spacing(6),
    ":hover": {background: "inherit"},
  },
  chatbotMessageList: {
    borderTop: "4px solid grey",
    flexGrow: 1,
    overflow: "auto",
    "&::-webkit-scrollbar": {
      width: "6px",
      backgroundColor: theme.palette.mode === "dark" ? "#333" : "#ccc",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: theme.palette.mode === "dark" ? "#666" : "#999",
    },
  },
  chatbotEndButton: {
    borderRadius: 0,
    marginLeft: 0,
    marginTop: theme.spacing(1),
  },
}));

export const useChatbotMessageStyles = makeStyles()((theme, {isHuman}={}) => ({
  chatbotMessageItem: {
    justifyContent: isHuman ? "flex-end" : "flex-start",
    padding: 0,
    textAlign: isHuman ? "right" : "left",
  },
  chatbotMessageWrapper: {
    color: "white",
    backgroundColor: isHuman ?
      theme.palette.mode === "dark" ? "#195882" : "#3291d1" :
      theme.palette.mode === "dark" ? "#1e4b57" : "#286c7e",
    borderRadius: 0,
    display: "inline-block",
    marginBottom: theme.spacing(0.5),
    maxWidth: "100%",
    overflowWrap: "break-word",
    padding: `${theme.spacing(1)} ${theme.spacing(1.5)}`,
    whiteSpace: "pre-wrap",
    wordWrap: "break-word",
  },
  chatbotMessage: {
    display: "inline-block",
    maxWidth: "100%",
    overflowWrap: "break-word",
    whiteSpace: "pre-wrap",
    wordWrap: "break-word",
  },
  chatbotMessageText: {
    color: theme.palette.mode === "dark" ? "#c7c7c7" : "#fff",
  },
  chatbotMessageLoadingWrapper: {
    alignItems: "center",
    backgroundColor: theme.palette.mode === "dark" ? "#1e4b57" : "#4391a7",
    borderRadius: 0,
    color: "white",
    display: "flex",
    justifyContent: "center",
    marginBottom: theme.spacing(0.5),
    maxWidth: "75%",
    padding: `${theme.spacing(1)} ${theme.spacing(1.5)}`,
    overflowWrap: "break-word",
    wordWrap: "break-word",
  },
}));
