import React from "react";
import {Navigate, Route, Routes, useLocation} from "react-router-dom";
import {Box} from "@mui/material";
import SidebarForAllViews from "../../components/sidebar/SidebarForAllViews";
import UserProfile from "../user/UserProfile";
import {OverviewProvider} from "../../components/overview/OverviewProvider";
import {useRegScoutCore} from "../../resources/RegScoutCoreProvider";
import {Home, Detail, RegulationsProvider} from "../../pages/regulations";
import PricingPage from "../pricing";
import ManageSubscription from "../pricing/ManageSubscription";
import MandatoryUserDataModal from "../../components/login/MandatoryUserDataModal";
import NewsFeed from "../newsFeed";

const RegulationsCore = () => (
  <RegulationsProvider>
    <OverviewProvider>
      <RegulationsContent />
    </OverviewProvider>
  </RegulationsProvider>
);

const RegulationsContent = () => {
  const location = useLocation();
  const locationIncludesPayment = location.search.includes("?payment=successful");

  const {isPlatform} = useRegScoutCore();
  const path = !isPlatform ? "" : "/reports/research";

  return (
    <Box display="flex" width={"100%"}>
      {!isPlatform && (
        <Box id="sidebar-container">
          <SidebarForAllViews />
        </Box>
      )}
      {!isPlatform && <MandatoryUserDataModal />}
      <Routes>
        <Route path={`${path}/`}>
          <Route
            path=":payment?"
            element={
              locationIncludesPayment ?
                <Navigate to="/subscription?payment=successful" /> : <Home />
            }
          />
        </Route>
        <Route path={`${path}/subscription`} element={<ManageSubscription />} />
        <Route path={`${path}/news`} element={<NewsFeed />} />
        <Route path={`${path}/pricing`} element={<PricingPage />} />
        <Route path={`${path}/profile`} element={<UserProfile />} />
        <Route path={`${path}/regulation/:id`} element={<Detail />} />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </Box>
  );
};

export default RegulationsCore;
