import React from "react";
import PropTypes from "prop-types";
import {Box, IconButton, ListItemButton, ListItemIcon, ListItemText, Tooltip} from "@mui/material";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import {makeStyles} from "tss-react/mui";
import {smallDeviceWidth} from "../resources/theme";

export const useBackArrowListItemStyles = makeStyles()((theme) => ({
  backArrowIconBox: {
    marginLeft: theme.spacing(-0.25),
    [`@media (max-device-width: ${smallDeviceWidth})`]: {
      marginLeft: theme.spacing(0.75),
    },
  },
  backArrowButtonBox: {
    ".MuiListItemText-root": {
      marginLeft: theme.spacing(-1),
      [`@media (max-device-width: ${smallDeviceWidth})`]: {
        marginLeft: theme.spacing(-0.25),
      },
    },
  },
}));

const BackArrowListItem = ({iconOnly, onClick, title="Back", ButtonProps}) => {
  const {classes} = useBackArrowListItemStyles();
  if (iconOnly) {
    return (
      <Tooltip title={title} placement="right">
        <Box display="flex" justifyContent="center" className={classes.backArrowIconBox}>
          <IconButton aria-label={title.toLocaleLowerCase()} onClick={onClick}>
            <ArrowBackOutlinedIcon />
          </IconButton>
        </Box>
      </Tooltip>
    );
  };

  return (
    <ListItemButton onClick={onClick} {...ButtonProps} className={classes.backArrowButtonBox}>
      <ListItemIcon>
        <ArrowBackOutlinedIcon />
      </ListItemIcon>
      <ListItemText primary={title} />
    </ListItemButton>
  );
};

BackArrowListItem.propTypes = {
  iconOnly: PropTypes.bool,
  onClick: PropTypes.func,
  sx: PropTypes.object,
  title: PropTypes.string,
  ButtonProps: PropTypes.object,
};

export default BackArrowListItem;
