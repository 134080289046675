import React from "react";
import {useRegulations} from "../../../pages/regulations/contexts/RegulationsProvider";
import {Box} from "@mui/material";
import CustomSwitch from "./CustomSwitch";

const SwitchControls = () => {
  const {
    hideFilteredItems,
    setHideFilteredItems,
    mapOpen,
    setMapOpen,
    useRiskMapOverlay,
    setUseRiskMapOverlay,
  } = useRegulations();

  return (<>
    <Box display="flex" justifyContent="space-between" alignItems="center" px={3} pt={0.5} pb={1.5}>
      <CustomSwitch
        label="Map View"
        tooltip="Display matching regulations on a map"
        isOpen={mapOpen}
        setIsOpen={setMapOpen}
      />
    </Box>
    <Box display="flex" justifyContent="space-between" alignItems="center" px={3} pt={0.5} pb={1.5}>
      {mapOpen ?
        <CustomSwitch
          label="Risk Overlay"
          tooltip="Highlight the map by risk level or a single color"
          isOpen={useRiskMapOverlay}
          setIsOpen={() => setUseRiskMapOverlay((currentState) => !currentState)}
        /> :
        <CustomSwitch
          label="Hide Filtered Items"
          tooltip="Hide regulations that don't match instead of dimming"
          isOpen={hideFilteredItems}
          setIsOpen={setHideFilteredItems}
        />
      }
    </Box>
  </>);
};

export default SwitchControls;
