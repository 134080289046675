import {Box, Paper, Skeleton} from "@mui/material";
import React from "react";
import {useDetailStyles} from "./styles/detailStyles";

const DetailSkeleton = () => {
  const {classes} = useDetailStyles();

  return (
    <Box className={classes.paperWrapper} sx={{width: "100%"}} id="detail-skeleton">
      <Paper elevation={4} sx={{borderRadius: 0, minHeight: "100vh"}}>
        <Box display="flex" minHeight="100%" padding={3} flexDirection="column">
          <Box display="flex" flexDirection="row">
            <Box minWidth="75%" width="75%" maxWidth="75%" flexDirection="column">
              <Skeleton variant="text" width={320} />
              <Skeleton variant="text" width={200} height={56} sx={{marginTop: -1}} />
              <Box display="flex" gap={1} pb={2}>
                <Skeleton variant="rectangular" width={100} height={32} />
                <Skeleton variant="rectangular" width={100} height={32} />
              </Box>
              <Skeleton variant="text" width={250} height={24} />
              <Skeleton variant="text" width={250} height={24} />
              <Box mt={2} className={classes.sourceBlock}>
                <Skeleton variant="text" width={75} height={40} />
                <Skeleton variant="text" width={250} height={24} sx={{marginTop: -1}} />
              </Box>
            </Box>
            <Box display="flex" mt={2} minWidth="25%" maxWidth="25%" justifyContent="right">
              <Skeleton variant="rectangular" width={220} height={133} style={{marginRight: 4}} />
            </Box>
          </Box>
          <Box mt={3}>
            <Skeleton variant="text" width={120} height={40} />
            <Skeleton variant="rectangular" height={75} />
          </Box>
          <Box mt={2}>
            <Skeleton variant="text" width={200} height={40} />
            <Skeleton variant="rectangular" height={1500} />
          </Box>
        </Box>
      </Paper>
    </Box>
  );
};

export default DetailSkeleton;
