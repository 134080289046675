import {useState, useEffect, useMemo} from "react";
import {
  configureRegulation,
  filterIncludeExcludes,
  filterRegulations,
  generateFilterOptions,
  generateTagTypeData,
  getTypeLabel,
  sortAndCombineRegulations,
} from "../utils/dataTransformers";
import {useQuery} from "@apollo/client";
import {GET_REGULATIONS_LIST} from "../queries";
import {
  every, findWhere, flatten, isArray, isEmpty, keys, map, pluck, sortBy, uniq, values,
} from "underscore";

export const useListData = () => {
  const [currentFilters, setCurrentFilters] = useState({
    records: [],
    regulationTypes: [],
    userInclude: [],
    userExclude: [],
  });
  const [currentSort, setCurrentSort] = useState("regulator_asc");
  const {data, loading} = useQuery(GET_REGULATIONS_LIST);

  const clearFilters = () => {
    setCurrentFilters({records: [], userInclude: [], userExclude: []});
  };

  const allRegulations = useMemo(() => {
    if (!data?.regulations) return;
    return map(data.regulations, (regulation) => configureRegulation(regulation, data?.tagTypes));
  }, [data]);

  const allRegulationTypes = useMemo(() => {
    if (!allRegulations) return;
    const regulationTypes = pluck(allRegulations, "type");
    const uniqueRegulationTypes = uniq(regulationTypes);
    const regulationTypesWithDisplayValues = map(uniqueRegulationTypes, (type) => ({
      ...type,
      displayValue: getTypeLabel(type.name),
    }));
    return sortBy(regulationTypesWithDisplayValues, "displayValue");
  }, [allRegulations]);

  const tagTypeOptions = useMemo(() => {
    if (!allRegulations) return;
    return generateFilterOptions(allRegulations);
  }, [allRegulations]);

  const tagTypeData = useMemo(() => {
    if (!tagTypeOptions) return;
    const tagTypeData = generateTagTypeData(tagTypeOptions);
    return tagTypeData;
  }, [tagTypeOptions]);

  useEffect(() => {
    if (!tagTypeOptions) return;

    setCurrentFilters((prevFilters) => {
      const newFilters = {...prevFilters};
      for (const tagType of keys(tagTypeOptions)) {
        newFilters[tagType] = [];
      }
      return newFilters;
    });
  }, [tagTypeOptions]);

  const matchedRegulations = useMemo(() => {
    if (!allRegulations) return;
    return filterRegulations(allRegulations, currentFilters);
  }, [allRegulations, currentFilters]);

  const sortedData = useMemo(() => {
    if (!allRegulations) return;
    return sortAndCombineRegulations(matchedRegulations, currentSort, allRegulations);
  }, [allRegulations, matchedRegulations, currentSort]);

  useEffect(() => {
    if (!isArray(currentFilters?.Geography)) return;

    setCurrentFilters((prevFilters) => {
      const newRecordsFilters = [];

      for (const selectedJurisdiction of currentFilters?.Geography) {
        const prevRecords = prevFilters.records;
        const recordsEntryExist = findWhere(prevRecords, {
          value: selectedJurisdiction.displayValue,
        });

        if (recordsEntryExist) {
          newRecordsFilters.push(recordsEntryExist);
          continue;
        }

        newRecordsFilters.push({value: selectedJurisdiction.displayValue, count: 0});
      }

      return {
        ...prevFilters,
        records: newRecordsFilters,
      };
    });
  }, [currentFilters?.Geography]);


  useEffect(() => {
    if (!isArray(currentFilters?.userExclude) || isEmpty(currentFilters?.userInclude)) return;
    const currentIncludeIds = map(currentFilters.userInclude, ({id}) => id);
    const currentExcludeIds = map(currentFilters.userExclude, ({id}) => id);
    if (every(currentIncludeIds, (id) => !currentExcludeIds.includes(id))) return;

    setCurrentFilters((prevFilters) => filterIncludeExcludes(prevFilters));
  }, [currentFilters?.userExclude]);

  useEffect(() => {
    if (!isArray(currentFilters?.userInclude) || isEmpty(currentFilters?.userExclude)) return;
    const currentIncludeIds = map(currentFilters.userInclude, ({id}) => id);
    const currentExcludeIds = map(currentFilters.userExclude, ({id}) => id);
    if (every(currentExcludeIds, (id) => !currentIncludeIds.includes(id))) return;

    setCurrentFilters((prevFilters) => filterIncludeExcludes(prevFilters, true));
  }, [currentFilters?.userInclude]);

  return {
    regulationsListData: sortedData,
    listQueryLoading: loading,
    allRegulations,
    allRegulationTypes,
    currentSort,
    setCurrentSort,
    areFiltersActive: !isEmpty(flatten(values(currentFilters))),
    clearFilters,
    currentFilters,
    setCurrentFilters,
    tagTypeOptions,
    tagTypeData,
  };
};
