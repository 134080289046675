import React from "react";
import PropTypes from "prop-types";
import {useRegScoutCore} from "../../../resources/RegScoutCoreProvider";
import {NavLink} from "react-router-dom";
import {Chip, Link, Tooltip} from "@mui/material";
import {riskScoreDefinition} from "../../../resources/environment";
import {map} from "underscore";

const TagChips = ({chips, disableHover = false}) => {
  const {isProfessional, needsUpgrade} = useRegScoutCore();

  const upgradeLabel = "Upgrade for Risk Score";
  const upgradeLink = (
    <Link component={NavLink} to="/pricing" target="_blank">
      here
    </Link>
  );
  const upgradeMessage =
    isProfessional ?
      <>Click {upgradeLink} to upgrade and see exact scores.</> :
      <>Click {upgradeLink} to upgrade.</>;
  const upgradeTooltip = (
    <>
      {riskScoreDefinition}
      <br />
      <br />
      {upgradeMessage}
    </>
  );

  return map(chips, (tag, index) => {
    const {color, fill, label, tooltip, type} = tag;
    const upgradeRequired = needsUpgrade && type === "risk";
    return (
      <Tooltip
        title={
          disableHover ? "" : upgradeRequired || (isProfessional && type === "risk") ?
            upgradeTooltip : tooltip
        }
        placement="bottom-start"
        onClick={(event) => event.stopPropagation()}
        key={index}
        TransitionProps={{
          sx: {backgroundColor: "rgba(97, 97, 97, 0.98) !important"},
        }}
      >
        <Chip
          label={upgradeRequired ? upgradeLabel : label}
          color={upgradeRequired ? "default" : color}
          variant="filled"
          clickable={false}
          onClick={(event) => event.stopPropagation()}
          sx={(theme) => ({
            backgroundColor: upgradeRequired ? undefined : fill,
            color:
              upgradeRequired ? undefined : theme.palette.primary.contrastText,
            cursor: "default",
          })}
        />
      </Tooltip>
    );
  });
};

TagChips.propTypes = {
  chips: PropTypes.array,
  disableHover: PropTypes.bool,
};

export default TagChips;
