import React from "react";
import PropTypes from "prop-types";
import {Button} from "@mui/material";

const FakeButton = ({
  backgroundColor, color, className, label, size="small", variant="outlined",
}) => (
  <Button
    className={className}
    color={color}
    disableRipple
    disableFocusRipple
    disableTouchRipple
    size={size}
    sx={{userSelect: "none", cursor: "default", ":hover": {backgroundColor}}}
    variant={variant}
  >
    {label}
  </Button>
);

FakeButton.propTypes = {
  backgroundColor: PropTypes.string,
  color: PropTypes.string,
  className: PropTypes.any,
  label: PropTypes.string.isRequired,
  size: PropTypes.string,
  variant: PropTypes.string,
};

export default FakeButton;
