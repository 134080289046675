import React, {useContext, createContext, useState} from "react";
import PropTypes from "prop-types";
import {useListData} from "../hooks/useListData";
import {useMediaQuery} from "@mui/material";
import {smallDeviceWidth} from "../../../resources/theme";
import {useRegScoutCore} from "../../../resources/RegScoutCoreProvider";
import {isNull} from "underscore";

export const RegulationsContext = createContext({
  sidebarOpen: true,
  setSidebarOpen: () => {},
});

export const RegulationsProvider = ({children}) => {
  const {isPlatform} = useRegScoutCore();
  const hideFilterBar = isNull(isPlatform);
  const isSmallDevice = useMediaQuery(
      `(max-device-width: ${smallDeviceWidth})`,
  );

  const [sidebarOpen, setSidebarOpen] = useState(
    hideFilterBar ? !isSmallDevice : !isPlatform,
  );
  const [hideFilteredItems, setHideFilteredItems] = useState(false);
  const [mapOpen, setMapOpen] = useState(false);
  const [mapInView, setMapInView] = useState("world");
  const [useRiskMapOverlay, setUseRiskMapOverlay] = useState(true);

  const {
    regulationsListData,
    listQueryLoading,
    allRegulations,
    allRegulationTypes,
    currentSort,
    setCurrentSort,
    areFiltersActive,
    clearFilters,
    currentFilters,
    setCurrentFilters,
    tagTypeOptions,
    tagTypeData,
  } = useListData();

  return (
    <RegulationsContext.Provider
      value={{
        regulationsListData,
        listQueryLoading,
        allRegulations,
        allRegulationTypes,
        currentSort,
        setCurrentSort,
        areFiltersActive,
        clearFilters,
        currentFilters,
        setCurrentFilters,
        tagTypeOptions,
        tagTypeData,
        sidebarOpen,
        setSidebarOpen,
        hideFilteredItems,
        setHideFilteredItems,
        mapOpen,
        setMapOpen,
        mapInView,
        setMapInView,
        useRiskMapOverlay,
        setUseRiskMapOverlay,
      }}
    >
      {children}
    </RegulationsContext.Provider>
  );
};

RegulationsProvider.propTypes = {
  activeTheme: PropTypes.object,
  children: PropTypes.any,
};

export const useRegulations = () => {
  return useContext(RegulationsContext);
};
