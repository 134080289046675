import React from "react";
import PropTypes from "prop-types";
import {Box, FormControlLabel, Switch, Tooltip} from "@mui/material";
import {makeStyles} from "tss-react/mui";
import {smallDeviceWidth} from "../../../resources/theme";
import {isFunction, isString} from "underscore";

export const useSwitchStyles = makeStyles()((theme) => ({
  customSwitch: {
    ".MuiFormControlLabel-label": {
      marginLeft: theme.spacing(1.25),
      [`@media (max-device-width: ${smallDeviceWidth})`]: {
        marginLeft: theme.spacing(2),
      },
    },
  },
}));

const CustomSwitch = ({label, isOpen, setIsOpen, disabled, tooltip}) => {
  const {classes} = useSwitchStyles();

  const id = isString(label) ? label.replaceAll(" ", "-").toLowerCase() : "custom";

  return (
    <Box>
      <Tooltip title={tooltip} placement="right">
        <FormControlLabel
          label={label}
          control={
            <Switch
              size="small"
              checked={isOpen}
              onChange={() => isFunction(setIsOpen) && setIsOpen((isOpen) => !isOpen)}
              inputProps={{"aria-label": `toggle ${label?.toLowerCase()}`}}
              id={`${id}-switch`}
            />
          }
          disabled={disabled}
          className={classes.customSwitch}
          id={`${id}-label`}
        />
      </Tooltip>
    </Box>
  );
};

CustomSwitch.propTypes = {
  label: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  tooltip: PropTypes.string,
};

export default CustomSwitch;
