import {makeStyles} from "tss-react/mui";

export const useEditorStyles = makeStyles()((theme, {padding, hideToolbar, isPaid, shadow}={}) => ({
  basicEditor: {
    "&> .sun-editor": {
      backgroundColor: "transparent",
      "&> .se-container .se-wrapper .sun-editor-editable": {
        color: theme.palette.text.primary,
        backgroundColor: "transparent",
      },
    },
  },
  displayEditor: {
    "&> .sun-editor": {
      border: "none",
      backgroundColor: "transparent",
      boxShadow: shadow ? "0 0 5px 2px rgba(0,0,0,.35);" : undefined,
      padding: shadow ? theme.spacing(1) : undefined,
      userSelect: isPaid ? "unset" : undefined,
      "@media print": {
        border: "none",
      },
      "&> .se-container .se-wrapper .sun-editor-editable": {
        padding: padding,
        height: "auto",
        color: theme.palette.text.primary,
        backgroundColor: "transparent",
      },
      "&> .se-container .se-wrapper .se-wrapper-inner": {
        minHeight: 0,
      },
      "&> .se-container .se-toolbar": {
        display: hideToolbar ? "none !important" : undefined,
      },
      "&> .se-container .se-toolbar-sticky-dummy": {
        display: hideToolbar ? "none !important" : undefined,
      },
    },
  },
}));

