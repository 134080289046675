import React from "react";
import PropTypes from "prop-types";
import {
  ListItemAvatar, ListItemButton, Avatar, ListItemText, useTheme, Typography,
} from "@mui/material";
import dayjs from "dayjs";
import {whichImage} from "./newsHelpers";

const NewsListElement = ({news, featured, classes, view="Classic"}) => {
  const theme = useTheme();

  const maxHeadlineLength = 100;
  const title = news.title && news.title.length > maxHeadlineLength ?
    `${news.title.slice(0, maxHeadlineLength)}...` : news.title;
  const cleanContent = news.content && news.content.length > 0 ?
    `${news.content.replace(/<\/?("[^"]*"|'[^']*'|[^>])*(>|$)/g, "")}` : "";
  const overlay = featured ? "Featured" :
    news.date ? dayjs(news.date).format("ll") : false;

  const avatarClass = view === "Classic" ? classes.avatarClassic : classes.avatarCompact;

  return (
    <ListItemButton component="a" href={news.link} target="_blank">
      <ListItemAvatar>
        <Avatar alt="News" src={whichImage(news, theme)} variant="square" className={avatarClass}/>
      </ListItemAvatar>
      <ListItemText
        primary={title}
        secondary={view==="Classic" ? cleanContent : null}
        className={classes.listText}
      />
      {overlay ?
        <Typography
          variant="body1"
          className={featured ? `${classes.overlay} ${classes.featured}` : classes.overlay}
        >
          {overlay}
        </Typography> :
        ""}
    </ListItemButton>
  );
};

NewsListElement.propTypes = {
  news: PropTypes.object.isRequired,
  featured: PropTypes.bool,
  classes: PropTypes.object.isRequired,
  view: PropTypes.string,
};

export default NewsListElement;
