import React from "react";
import {Box, CircularProgress, ListItem} from "@mui/material";
import {useChatbotMessageStyles} from "./chatbotStyles";

const ChatbotLoading = () => {
  const {classes} = useChatbotMessageStyles();
  return (
    <ListItem alignItems="flex-start" className={classes.chatbotMessageItem}>
      <Box className={classes.chatbotMessageLoadingWrapper}>
        <CircularProgress color="inherit" size={25} id="chat-loading" />
      </Box>
    </ListItem>
  );
};

export default ChatbotLoading;
