import {useMediaQuery} from "@mui/material";
import {createTheme} from "@mui/material/styles";
import {grey} from "@mui/material/colors";
import Cookies from "js-cookie";

// theme constants
export const themeCookieName = "theme";
export const themeCookieExpirationLength = 365; // days
export const breachRxColor = "#42a8d2";
const contrastTextDark = "#212121";
const contrastTextLight = "#ffffff";
export const contrastTextColor = (theme) => theme === "dark" ? contrastTextDark : contrastTextLight;
export const inverseTextColor = (theme) => theme === "dark" ? "#c7c7c7" : contrastTextDark;
export const drawerWidth = 320;
export const smallDeviceWidth = "768px";

export const cookieAttributes = (expiration) => ({
  expires: expiration,
  secure: true,
  sameSite: "strict",
});

const breakpoints = {
  values: {
    xs: 0,
    sm: 600,
    md: 900,
    lg: 1200,
    xl: 1536,
    xxl: 1980,
  },
};

const customTypography = (theme) => ({
  h1: {
    fontFamily: "Oswald, sans-serif",
    color: inverseTextColor(theme),
  },
  h2: {
    fontFamily: "Oswald, sans-serif",
    color: inverseTextColor(theme),
  },
  h3: {
    fontFamily: "Oswald, sans-serif",
    color: inverseTextColor(theme),
  },
  h4: {
    fontFamily: "Oswald, sans-serif",
    color: inverseTextColor(theme),
    [`@media (max-device-width: ${smallDeviceWidth})`]: {
      fontSize: "2.35rem !important",
    },
  },
  h5: {
    fontFamily: "Oswald, sans-serif",
    color: inverseTextColor(theme),
    [`@media (max-device-width: ${smallDeviceWidth})`]: {
      fontSize: "1.85rem !important",
    },
  },
  h6: {
    fontFamily: "Oswald, sans-serif",
    color: inverseTextColor(theme),
    [`@media (max-device-width: ${smallDeviceWidth})`]: {
      fontSize: "1.45rem !important",
    },
  },
  subtitle: {
    [`@media (max-device-width: ${smallDeviceWidth})`]: {
      fontSize: "1.15rem",
    },
  },
  subtitle1: {
    color: inverseTextColor(theme),
  },
  subtitle2: {
    color: inverseTextColor(theme),
  },
  body1: {
    color: inverseTextColor(theme),
    [`@media (max-device-width: ${smallDeviceWidth})`]: {
      fontSize: "1.25rem",
    },
  },
  body2: {
    color: inverseTextColor(theme),
    [`@media (max-device-width: ${smallDeviceWidth})`]: {
      fontSize: "1.15rem",
    },
  },
  caption: {
    color: inverseTextColor(theme),
    [`@media (max-device-width: ${smallDeviceWidth})`]: {
      fontSize: "1.05rem",
    },
  },
  overline: {
    color: inverseTextColor(theme),
  },
});

const themeOverrides = (theme) => ({
  MuiAlert: {
    styleOverrides: {
      root: {borderRadius: 0},
    },
  },
  MuiAutocomplete: {
    styleOverrides: {
      paper: {borderRadius: 0},
    },
  },
  MuiBadge: {
    styleOverrides: {
      badge: {borderRadius: 0},
    },
  },
  MuiButton: {
    styleOverrides: {
      root: {borderRadius: 0},
    },
    variants: [{
      props: {variant: "contained", color: "grey"},
      style: {
        color: theme === "light" ? "#ffffffde" : "#000000de",
      },
    }, {
      props: {variant: "outlined", color: "grey"},
      style: {
        color: theme === "light" ? "#646777" : "#dddddd",
        borderColor: theme === "light" ? "#0000003a" : "#ffffff3a",
        "&.Mui-disabled": {
          border: `1px solid ${theme === "light" ? "#0000001f" : "#ffffff1f"}`,
        },
        "&:hover": {
          borderColor: theme === "light" ? "#0000003a" : "#ffffff3a",
          backgroundColor: theme === "light" ? "#6467770a" : "#dddddd0a",
        },
      },
    }, {
      props: {variant: "text", color: "grey"},
      style: {
        color: theme === "light" ? "#646777" : "#dddddd",
        "&:hover": {
          backgroundColor: theme === "light" ? "#6467770a" : "#dddddd0a",
        },
      },
    }],
  },
  MuiButtonBase: {
    styleOverrides: {
      root: {borderRadius: 0},
    },
  },
  MuiCard: {
    styleOverrides: {
      root: {borderRadius: 0},
    },
  },
  MuiChip: {
    styleOverrides: {
      root: {
        borderRadius: 0,
        [`@media (max-device-width: ${smallDeviceWidth})`]: {
          fontSize: "1.1rem",
        },
      },
    },
  },
  MuiCssBaseline: {
    styleOverrides: {
      body: {
        fontSize: "0.875rem",
        lineHeight: 1.43,
        letterSpacing: "0.01071em",
      },
    },
  },
  MuiDataGrid: {
    styleOverrides: {
      root: {borderRadius: 0},
    },
  },
  MuiDataGridMenu: {
    styleOverrides: {
      root: {
        zIndex: 1500,
        "& div.MuiPaper-root": {
          borderRadius: 0,
        },
      },
    },
  },
  MuiDataGridPanel: {
    styleOverrides: {
      paper: {borderRadius: 0},
    },
  },
  MuiDialog: {
    styleOverrides: {
      paper: {borderRadius: 0},
    },
  },
  MuiDialogTitle: {
    styleOverrides: {
      root: {
        [`@media (max-device-width: ${smallDeviceWidth})`]: {
          fontSize: "1.65rem !important",
        },
      },
    },
  },
  MuiLink: {
    defaultProps: {
      underline: "hover",
    },
  },
  MuiListItem: {
    styleOverrides: {
      button: {
        "&:hover": {
          backgroundColor: `${breachRxColor} !important`,
          color: contrastTextColor(theme),
          "& svg": {
            fill: contrastTextColor(theme),
          },
        },
        "&:hover .MuiTypography-body1, &:hover .MuiTypography-body2": {
          color: contrastTextColor(theme),
        },
      },
    },
  },
  MuiMenu: {
    styleOverrides: {
      paper: {borderRadius: 0},
    },
  },
  MuiTooltip: {
    styleOverrides: {
      tooltip: {
        borderRadius: 0,
        "& .MuiTypography-caption": {
          color: "#ffffff",
        },
      },
    },
  },
  MuiOutlinedInput: {
    styleOverrides: {
      root: {borderRadius: 0},
    },
  },
  MuiPaper: {
    styleOverrides: {// this disables elevation color (darkTheme)
      // root: {backgroundImage: "unset"},
    },
  },
  MuiSnackbar: {
    styleOverrides: {
      root: {borderRadius: 0},
    },
  },
  MuiSvgIcon: {
    styleOverrides: {
      root: {
        [`@media (max-device-width: ${smallDeviceWidth})`]: {
          height: "1.35em",
          width: "1.35em",
        },
      },
    },
  },
  MuiSwitch: {
    styleOverrides: {
      sizeSmall: {
        [`@media (max-device-width: ${smallDeviceWidth})`]: {
          transform: "scale(1.25)",
        },
      },
    },
  },
  MuiTable: {
    styleOverrides: {
      root: {
        borderRadius: 0,
        borderWidth: 0,
      },
    },
  },
  MuiTextField: {
    styleOverrides: {
      root: {
        "& fieldset": {
          borderRadius: 0,
        },
      },
    },
  },
});

export const lightTheme = createTheme({
  fontFamily: "Roboto, sans-serif",
  palette: {
    mode: "light",
    background: {
      default: "#ffffff",
      paper: "#ffffff",
      info: "#e8f4fd",
    },
    draggable: {
      end: "#ecf1f3",
      accent: "#f5f5f5",
      selected: "#a7daef",
    },
    grey: {
      main: grey[700],
      dark: grey[800],
      light: grey[300],
      contrastText: "rgba(0, 0, 0, 0.87)",
    },
    layout: {
      chart: {
        colorScaleStart: "#003247",
        backgroundAccent: "#ffffff",
      },
      icon: {
        color: "#99a8ac",
      },
      map: {
        stroke: "#fff",
      },
      navLink: {
        color: undefined,
      },
      scrollbar: {
        thumb: "#b0b5b6",
        track: "#dadada",
      },
      overview: {
        height: "48px",
        boxShadow: "0 1px 5px 1px rgba(0, 0, 0, 0.11)",
      },
      sidebar: {
        boxShadow: "0 -5px 5px 1px rgba(0, 0, 0, 0.11)",
        overviewOffset: "44px",
      },
      topbar: {
        boxShadow: "0 2px 5px 0 rgba(0, 0, 0, 0.05)",
      },
      wizard: {
        background: "#ffffff",
        boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
      },
      frame: {
        boxShadow: "rgba(0, 0, 0, 0.24) 0px 1px 2px",
      },
    },
    primary: {
      main: breachRxColor,
      dark: "#227090",
      light: "#62b7da",
      ultralight: "#d4ebf3",
      megaLight: "#e6f7ff",
      contrastText: contrastTextLight,
    },
    secondary: {
      main: "#4260d2",
      dark: "#223990",
      light: "#627bda",
      contrastText: contrastTextLight,
    },
    neutral: {
      main: "#9faeb7",
      contrastText: contrastTextLight,
    },
    border: "1px solid #e0e0e0",
    chat: {background: "#f5f5f5"},
    chip: {background: "#e0e0e0"},
    tabs: {background: "#f5f5f5"},
    test: {success: "#009000", failure: "#d00000", overridden: "#4260d2", notExecuted: "grey"},
    text: {primary: "#646777", secondary: "#646777", info: "#0d3c61"},
    logo: require("~/public/logo-light.png"),
  },
  breakpoints,
  typography: customTypography("light"),
  components: themeOverrides("light"),
});

export const darkTheme = createTheme({
  fontFamily: "Roboto, sans-serif",
  palette: {
    mode: "dark",
    background: {
      default: "#212121",
      paper: "#212121",
      info: "#061014bf",
    },
    draggable: {
      end: "#353535",
      accent: "#2d2d2d",
      selected: "#19536b",
    },
    grey: {
      main: grey[300],
      dark: grey[400],
      light: grey[700],
      contrastText: "#fff",
    },
    layout: {
      chart: {
        colorScaleStart: "#015173",
        backgroundAccent: "#29292c",
      },
      icon: {
        color: "#b1c3c8",
      },
      map: {
        stroke: "rgba(0, 0, 0, 0.87)",
      },
      navLink: {
        color: "#b563ff",
      },
      scrollbar: {
        thumb: "#5d6263",
        track: "#858686",
      },
      overview: {
        height: "48px",
        boxShadow: "0 1px 10px 1px rgba(0, 0, 0, 0.25)",
      },
      sidebar: {
        boxShadow: "0 -5px 10px 1px rgba(0, 0, 0, 0.25)",
        overviewOffset: "44px",
      },
      topbar: {
        boxShadow: "0 2px 15px 0 rgba(0, 0, 0, 0.25)",
      },
      wizard: {
        background: "#353535",
        boxShadow: "rgb(66 168 210 / 25%) 0px 0px 8px",
      },
      frame: {
        boxShadow: "rgba(38 89 110 / 25%) 0px 1px 6px",
      },
    },
    primary: {
      main: breachRxColor,
      dark: "#227090",
      light: "#62b7da",
      ultralight: "rgba(212, 235, 243, .2)",
      megaLight: "#e6f7ff",
      contrastText: contrastTextDark,
    },
    secondary: {
      main: "#4260d2",
      dark: "#223990",
      light: "#627bda",
      contrastText: "#fff",
    },
    neutral: {
      main: "#9faeb7",
      contrastText: contrastTextDark,
    },
    info: {
      main: "rgba(66, 168, 210, .75)",
    },
    border: "1px solid #3c3c3c",
    chat: {background: "#2d2d2d"},
    chip: {background: "#616161"},
    tabs: {background: "#2d2d2d"},
    test: {success: "#009000", failure: "#d00000", overridden: "#4260d2", notExecuted: "grey"},
    text: {primary: "#dddddd", secondary: "#999999", info: "#b3dcedbf"},
    logo: require("~/public/logo-dark.png"),
  },
  breakpoints,
  typography: customTypography("dark"),
  components: themeOverrides("dark"),
});

export const initializeTheme = () => {
  const savedTheme = Cookies.get(themeCookieName);
  if (!savedTheme) {
    const prefersDark = window.matchMedia && useMediaQuery("(prefers-color-scheme: dark)");
    if (prefersDark) {
      Cookies.set(themeCookieName, "dark", cookieAttributes(themeCookieExpirationLength));
      return darkTheme;
    } else {
      Cookies.set(themeCookieName, "light", cookieAttributes(themeCookieExpirationLength));
      return lightTheme;
    };
  } else {
    if (savedTheme === "dark") return darkTheme;
  }
  return (lightTheme);
};

export const toggleTheme = ({activeTheme, setActiveTheme}) => {
  if (activeTheme === lightTheme) {
    setActiveTheme(darkTheme);
    Cookies.set(themeCookieName, "dark", cookieAttributes(themeCookieExpirationLength));
  } else {
    setActiveTheme(lightTheme);
    Cookies.set(themeCookieName, "light", cookieAttributes(themeCookieExpirationLength));
  };
};

export default darkTheme;
