import {makeStyles} from "tss-react/mui";

export const useBannerStyles = makeStyles()((theme) => ({
  bannerText: {
    color: "black",
    cursor: "default",
  },
  coreNotification: {
    backgroundColor: "rgb(220, 237, 225)",
    display: "block",
    position: "fixed",
    top: 0,
    left: 0,
    padding: `0 ${theme.spacing(3)}`,
    width: "100%",
    zIndex: 5000,
  },
  closeIcon: {
    position: "relative",
    float: "right",
    right: 0,
    paddingTop: 2,
    color: "rgb(40, 123, 65)",
    cursor: "pointer",
  },
  platformLink: {
    ":visited": {
      color: "blue",
    },
  },
}));
