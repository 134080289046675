import {makeStyles} from "tss-react/mui";

export const usePricingStyles = makeStyles()((theme) => ({
  subscriptionWrapper: {
    display: "flex",
    width: "100%",
    height: "100%",
    minHeight: "100vh",
    backgroundColor: theme.palette.background.default,
  },
}));
