import {gql} from "@apollo/client";

export const SEND_FEEDBACK = gql`
mutation SendFeedback (
  $feedback: String!, 
  ) {
  sendFeedback (
    feedback: $feedback
  )
}`;
