import React from "react";
import {createRoot} from "react-dom/client";
import {CssBaseline} from "@mui/material";
import App from "@regscout/core";
import LoginProvider, {useLogin} from "./src/components/login/LoginProvider";
import ApolloClientProvider from "./src/components/graphql/Apollo";
import {initializeLogRocket} from "./src/components/logging/logRocket";
import {initializeAmplitude} from "./src/components/metrics/amplitude";
import {emitMetric, emitStartStopMetric} from "./shared/metrics";
import "@fontsource/oswald/300.css";
import "@fontsource/oswald/400.css";
import "@fontsource/oswald/500.css";
import "@fontsource/oswald/700.css";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

initializeLogRocket();
initializeAmplitude();

const container = document.getElementById("app");
const root = createRoot(container);
root.render(<React.StrictMode>
  <CssBaseline />
  <LoginProvider>
    <ApolloClientProvider>
      <App metrics={{emitMetric, emitStartStopMetric}} useLogin={useLogin} normalizeCSS={true}/>
    </ApolloClientProvider>
  </LoginProvider>
</React.StrictMode>);
