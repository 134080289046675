import React from "react";
import PropTypes from "prop-types";
import {Link, useNavigate} from "react-router-dom";
import {Box, Button, Card, Typography} from "@mui/material";
import UserFilterButton from "./UserFilter";
import TagChips from "./TagChips";
import dayjs from "dayjs";
import {generateChips} from "../utils/dataTransformers";
import {useRegulations} from "../contexts/RegulationsProvider";
import {useRegScoutCore} from "../../../resources/RegScoutCoreProvider";
import {useRegulationsStyles} from "./styles/Styles";
import {useDetailStyles} from "./styles/detailStyles";
import {breachRxColor} from "../../../resources/theme";
import FakeButton from "../../../components/upgrade/FakeButton";

const ListCard = ({
  regulation,
  customList,
  customNavigateState,
  upgradeRequired = false,
}) => {
  const navigate = useNavigate();
  const {areFiltersActive, hideFilteredItems} = useRegulations();
  const {classes, cx} = useRegulationsStyles();
  const {classes: detailClasses} = useDetailStyles();
  const {isPlatform} = useRegScoutCore();

  if (hideFilteredItems && regulation.filtered && !upgradeRequired) return null;

  const chips = generateChips(regulation);
  const classIfFiltered =
    upgradeRequired ? detailClasses.upgradeContainer :
    regulation.filtered ? classes.filteredCard : "";
  const sinceIfEffective =
    dayjs().isAfter(dayjs(regulation.effectiveAt)) ? "since " : "";
  const effectiveSince =
    regulation.effectiveAt ?
      `- Effective ${sinceIfEffective}${dayjs(regulation.effectiveAt).format("LL")}` : "";
  const path = !isPlatform ? "/regulation" : "/reports/research/regulation";

  return (
    <Card
      className={classes.card}
      elevation={
        !areFiltersActive || (regulation.filtered && !upgradeRequired) ? 1 : 3
      }
      onClick={
        upgradeRequired ? undefined : (
          () =>
            navigate(`${path}/${regulation.id}`, {
              state: customNavigateState,
            })
        )
      }
      sx={upgradeRequired ? {cursor: "default !important"} : undefined}
    >
      <Box
        className={upgradeRequired ? detailClasses.upgradeContainer : undefined}
      >
        <Box>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography
              variant="caption"
              className={cx(classes.cardHeader, classIfFiltered)}
            >
              {regulation.legalType} {effectiveSince}
            </Typography>
            {upgradeRequired ?
              <FakeButton
                label="Exclude"
                color="neutral"
                className={classes.userFilterButton}
              /> : !customList ?
              <UserFilterButton
                regulation={regulation}
                isFiltered={regulation.filtered}
              /> : null}
          </Box>
          <Typography
            variant="h5"
            sx={{opacity: regulation.filtered ? 0.6 : undefined}}
          >
            {regulation.regulator}
          </Typography>
        </Box>
        <Box py={1} className={classIfFiltered}>
          <Typography
            variant="body1"
            dangerouslySetInnerHTML={{__html: regulation?.descriptionDisplay}}
            className={classes.description}
            sx={(theme) => ({color: theme.palette.text.primary})}
          />
        </Box>
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        gap={1}
        className={classIfFiltered}
      >
        <Box>
          <Typography variant="subtitle">
            {regulation?.deadlineDisplay}
          </Typography>
        </Box>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box display="flex" gap={1}>
            <TagChips chips={chips} disableHover={upgradeRequired} />
          </Box>
          {upgradeRequired ?
            <FakeButton
              label="View"
              variant="contained"
              size="medium"
              className={classes.viewButton}
              backgroundColor={breachRxColor}
            /> : <Button
              to={`${path}/${regulation.id}`}
              component={Link}
              variant="contained"
              className={classes.viewButton}
            >
              View
            </Button>
          }
        </Box>
      </Box>
    </Card>
  );
};

ListCard.propTypes = {
  customList: PropTypes.array,
  customNavigateState: PropTypes.object,
  regulation: PropTypes.object,
  upgradeRequired: PropTypes.bool,
};

export default ListCard;
