import * as yup from "yup";
import YupPassword from "yup-password";
import {yupResolver} from "@hookform/resolvers/yup";

// eslint-disable-next-line new-cap
YupPassword(yup);

const registerResolver = yupResolver(yup.object().shape({
  email: yup.string().email().required("Email is required."),
  customerName: yup.string().required("Full Name is required."),
  password: yup.string().required("Password is required.")
      .min(10, "Password must contain 10 or more characters.")
      .minLowercase(1, "Password must contain at least 1 lowercase character.")
      .minUppercase(1, "Password must contain at least 1 uppercase character.")
      .minNumbers(1, "Password must contain at least 1 number.")
      .minSymbols(1, "Password must contain at least 1 special character."),
  confirmPassword: yup.string().oneOf(
      [yup.ref("password"), null], "Passwords must match.",
  ).required("Confirm password is required."),
}).required());

const resetResolver = yupResolver(yup.object().shape({
  password: yup.string().required("Password is required.")
      .min(10, "Password must contain 10 or more characters.")
      .minLowercase(1, "Password must contain at least 1 lowercase character.")
      .minUppercase(1, "Password must contain at least 1 uppercase character.")
      .minNumbers(1, "Password must contain at least 1 number.")
      .minSymbols(1, "Password must contain at least 1 special character."),
  confirmPassword: yup.string().oneOf(
      [yup.ref("password"), null], "Passwords must match.",
  ).required("Confirm password is required."),
}).required());

export {registerResolver, resetResolver};
