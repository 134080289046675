import React, {createContext, useContext, useState} from "react";
import PropTypes from "prop-types";

export const OverviewContext = createContext({
  isOpen: false,
  openOverview: () => {},
  closeOverview: () => {},
  step: 0,
  previousStep: () => {},
  nextStep: () => {},
});

export const OverviewProvider = ({children}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [step, setStep] = useState(0);

  const handleClose = () => {
    setIsOpen(false);
    setStep(0);
  };

  return (
    <OverviewContext.Provider value={{
      isOpen,
      openOverview: () => setIsOpen(true),
      closeOverview: handleClose,
      step,
      previousStep: () => setStep((currentStep) => currentStep - 1),
      nextStep: () => setStep((currentStep) => currentStep + 1),
    }}>
      {children}
    </OverviewContext.Provider>
  );
};

OverviewProvider.propTypes = {
  children: PropTypes.any,
};

export const useOverview = () => {
  return useContext(OverviewContext);
};
