import React from "react";
import PropTypes from "prop-types";
import {Button} from "@mui/material";
import {useRegulations} from "../contexts/RegulationsProvider";
import NotInterestedOutlinedIcon from "@mui/icons-material/NotInterestedOutlined";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import {useRegulationsStyles} from "./styles/Styles";


const UserFilterButton = ({regulation, isFiltered}) => {
  const {setCurrentFilters} = useRegulations();
  const {classes} = useRegulationsStyles();
  const title = isFiltered ? "Include" : "Exclude";
  const filterType = isFiltered ? "userInclude" : "userExclude";

  const handleClick = (event) => {
    event.stopPropagation();
    setCurrentFilters((prev) => ({
      ...prev,
      [filterType]: [
        ...prev[filterType],
        regulation,
      ],
    }));
  };

  return (
    <Button
      color={isFiltered ? "success" : "neutral"}
      size="small"
      variant="outlined"
      onClick={handleClick}
      startIcon={isFiltered ? <AddOutlinedIcon /> : <NotInterestedOutlinedIcon />}
      className={classes.userFilterButton}
      sx={{opacity: isFiltered ? 0.6 : undefined}}
    >
      <span>{title}</span>
    </Button>
  );
};

UserFilterButton.propTypes = {
  regulation: PropTypes.object.isRequired,
  isFiltered: PropTypes.bool,
};


export default UserFilterButton;
