import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import {useFormContext} from "react-hook-form";
import {FormControlLabel, Switch} from "@mui/material";

const CustomSwitch = ({
  label,
  name,
  defaultChecked = false,
  disabled = false,
  disableLabelTypography = false,
  onChange,
  required,
}) => {
  const {register, unregister, setValue, watch} = useFormContext();
  const value = watch(name);
  const [checked, setChecked] = useState(defaultChecked);

  useEffect(() => {
    register(name, {required: required});
    return () => unregister(name);
  }, [name, register, unregister]);

  useEffect(() => {
    setValue(name, value ?? defaultChecked, {
      shouldValidate: true,
      shouldDirty: true,
      shouldTouch: true,
    });
    setChecked(value ?? defaultChecked);
  }, [value]);

  const handleChange = (event) => {
    const data = event.target.checked;
    setValue(name, data, {shouldValidate: true, shouldDirty: true});
    setChecked(data);
    onChange && onChange(data);
  };

  return (
    <FormControlLabel
      control={
        <Switch
          id={name}
          disabled={disabled}
          onChange={handleChange}
          color="primary"
          inputProps={{"data-testid": name?.replaceAll(" ", "_")}}
        />
      }
      label={label}
      disableTypography={disableLabelTypography}
      checked={checked}
    />
  );
};

CustomSwitch.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  name: PropTypes.string.isRequired,
  defaultChecked: PropTypes.bool,
  disabled: PropTypes.bool,
  disableLabelTypography: PropTypes.bool,
  onChange: PropTypes.func,
  required: PropTypes.bool,
};

export default CustomSwitch;
