import React from "react";
import {Box} from "@mui/material";
import UserProfileDetails from "./UserProfileDetails";

const UserProfile = () => {
  return (
    <Box display="flex" width="100%">
      <Box p={3} sx={{
        flexGrow: 1,
        bgcolor: "background.default",
        minHeight: "100vh",
        display: "flex",
        justifyContent: "center",
      }}>
        <UserProfileDetails />
      </Box>
    </Box>
  );
};

export default UserProfile;
