import {each, isEmpty} from "underscore";

export const renderNotificationTemplate = (content, values) => {
  if (isEmpty(content) || isEmpty(values)) return content;
  let renderContent = content ?? "";
  each(values, (value, key) => {
    renderContent = renderContent.replaceAll(key, value);
  });
  return renderContent;
};
