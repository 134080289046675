import {gql} from "@apollo/client";

const CORE_REGULATION_FIELDS = gql`
fragment CoreRegulationFields on Regulation {
  id
  regulator
  description
  legislationPassedAt
  effectiveAt
  legalType
  updatedAt
  type {
    id
    isDeleted
    createdAt
    updatedAt
    name
    displayName
  }
  tags {
    id
    tag {
      id
      isDeleted
      createdAt
      updatedAt
      typeId
      value
    }
  }
  notifications {
    id
    notification {
      id
      type
      conditions
    }
  }
}`;

const EXTENDED_REGULATION_FIELDS = gql`
${CORE_REGULATION_FIELDS}
fragment ExtendedRegulationFields on Regulation {
  ...CoreRegulationFields
  exceptions
  penalties
  piiDefinition
  otherRequirements
  source
  notifications {
    id
    notification {
      id
      content
      destination
      type
      conditions
      templateRevision
      templates(where:{isDraft:{equals:false}},orderBy:{revision:desc}) {
        id
        isDraft
        updatedAt
        content
        revision
      }
    }
  }
  leader {
    id
    name
    title
    office
    building
    address
    address2
    alternate
    email
    phone
  }
  newsItems {
    id
    newsItem {
      id
      isDraft
      title
      source
      amount
      currency
      publisher
      publishedAt
      notes
    }
  }
}`;

const TAG_TYPE_FIELDS = gql`
fragment TagTypeFields on TagType {
  id
  name
  displayName
  isVisible
  typeName
}`;

const GET_REGULATION = gql`
${EXTENDED_REGULATION_FIELDS}
${TAG_TYPE_FIELDS}
query GetRegulation($id: Int!) {
  regulation (where:{id: $id}) {
    ...ExtendedRegulationFields
  }
  tagTypes {
    ...TagTypeFields
  }
}`;

const GET_REGULATIONS_LIST = gql`
${CORE_REGULATION_FIELDS}
${TAG_TYPE_FIELDS}
query GetRegulations {
  regulations(where:{isDraft:{equals:false}}) {
    ...CoreRegulationFields
  }
  tagTypes {
    ...TagTypeFields
  }
}`;

export {
  GET_REGULATIONS_LIST,
  GET_REGULATION,
};

