import React, {useState, useEffect} from "react";
import PropTypes from "prop-types";
import {useFormContext} from "react-hook-form";
import {Divider, InputAdornment, TextField, Typography} from "@mui/material";
import AccountCircleOutlined from "@mui/icons-material/AccountCircleOutlined";
import LockOutlined from "@mui/icons-material/LockOutlined";
import {Link} from "react-router-dom";
import axios from "axios";
import {registerResolver} from "./yumSchemas";
import {emitMetric} from "../../../shared/metrics";
import {useLogin} from "./LoginProvider";
import LoginContainer from "./LoginContainer";
import {isEmpty} from "underscore";
import {useLoginStyles} from "./LoginStyles";

const AUTH_ENDPOINT = process.env.AUTH_ENDPOINT;

const RegisterContent = ({isSubmitted=false}) => {
  const {isDarkThemeSaved} = useLogin();
  const {classes} = useLoginStyles({isDarkThemeSaved});
  const formMethods = useFormContext();

  return <>
    <Typography variant="body2" alignSelf="center" color="gray" marginTop={1}>
      Reinvent your cybersecurity, privacy, and data breach regulatory analysis
    </Typography>
    <TextField
      {...formMethods.register("email", {required: true, disabled: isSubmitted})}
      id="email"
      label="Business Email"
      variant="outlined"
      size="medium"
      sx={{marginTop: 3}}
      InputLabelProps={{sx: {userSelect: "none"}}}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <AccountCircleOutlined className={classes.adornmentIcon} />
          </InputAdornment>
        ),
        sx: {borderRadius: 0},
      }}
      className={classes.loginTextField}
    />
    <Divider sx={{marginTop: 2}}/>
    <TextField
      {...formMethods.register("customerName", {required: true, disabled: isSubmitted})}
      label="Full Name"
      variant="outlined"
      size="medium"
      sx={{marginTop: 2}}
      InputLabelProps={{sx: {userSelect: "none"}}}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <AccountCircleOutlined className={classes.adornmentIcon} />
          </InputAdornment>
        ),
        sx: {borderRadius: 0},
      }}
      className={classes.loginTextField}
    />
    <Divider sx={{marginTop: 2}}/>
    <TextField
      {...formMethods.register("password", {required: true, disabled: isSubmitted})}
      type="password"
      id="password"
      label="Password"
      variant="outlined"
      size="medium"
      sx={{marginTop: 2}}
      InputLabelProps={{sx: {userSelect: "none"}}}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <LockOutlined className={classes.adornmentIcon} />
          </InputAdornment>
        ),
        sx: {borderRadius: 0},
      }}
      className={classes.loginTextField}
    />
    <TextField
      {...formMethods.register("confirmPassword", {required: true, disabled: isSubmitted})}
      type="password"
      id="confirmPassword"
      label="Confirm Password"
      variant="outlined"
      size="medium"
      sx={{marginTop: 2}}
      InputLabelProps={{sx: {userSelect: "none"}}}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <LockOutlined className={classes.adornmentIcon} />
          </InputAdornment>
        ),
        sx: {borderRadius: 0},
      }}
      className={classes.loginTextField}
    />
    <Typography variant="caption" alignSelf="center" fontStyle="italic" color="gray" marginTop={1}>
      No credit card required
    </Typography>
    <Typography variant="caption" sx={{marginTop: 0, padding: 1}} color="gray">
      By registering, you agree to the BreachRx Cyber RegScout™{" "}
      <Link
        to="https://www.breachrx.com/cyber-regscout-terms-of-service/"
        target="_blank"
        style={{color: "mediumpurple"}}
      >
        Terms of Service
      </Link>
    </Typography>
  </>;
};

RegisterContent.propTypes = {
  isSubmitted: PropTypes.bool,
};

const Register = () => {
  const [successMessage, setSuccessMessage] = useState();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [showReturnToLogin, setShowReturnToLogin] = useState(false);
  const {refreshError, setRefreshError} = useLogin();

  useEffect(() => {
    emitMetric("new registration - start", {});
    if (!isEmpty(refreshError)) setRefreshError({});
    return () => emitMetric("new registration - complete", {});
  }, []);

  const register = async (data, formMethods) => {
    setIsSubmitted(true);
    formMethods.setError("password", {type: "manual", message: ""});
    try {
      const response = await axios.post(`${AUTH_ENDPOINT}/register`, data);
      if (response.status === 200) {
        formMethods.reset();
        emitMetric("new registration - successful", {});
        setSuccessMessage(response.data.message);
      }
    } catch (error) {
      console.log(error);
      formMethods.setError("password", {
        type: "manual", message: error?.response?.data?.message ?? error?.message,
      });
      emitMetric("new registration - error", {});
    }
    setShowReturnToLogin(true);
  };

  return <LoginContainer
    submit={register}
    title="Register for Cyber RegScout&trade;"
    submitText="Register"
    disableSubmit={isSubmitted}
    formResolver={registerResolver}
    successMessage={successMessage}
    showReturnToLogin={showReturnToLogin}
  >
    <RegisterContent isSubmitted={isSubmitted} />
  </LoginContainer>;
};

export default Register;
