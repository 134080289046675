import React, {useMemo, useState} from "react";
import {Box, Typography, useMediaQuery} from "@mui/material";
import Close from "@mui/icons-material/Close";
import Cookies from "js-cookie";
import random from "random";
import shajs from "sha.js";
import {useRegScoutCore} from "../../resources/RegScoutCoreProvider";
import {useBannerStyles} from "./bannerStyles";

const CoreBanner = () => {
  const {cookieAttributes, isAdmin, isHighTier, emitMetric} = useRegScoutCore();
  const {classes} = useBannerStyles();
  const isSmallWindow = useMediaQuery("(max-width: 925px)");
  const encodedHide = useMemo(() => shajs("sha256").update("hide").digest("hex"), ["hide"]);
  const [hideBanner, setHideBanner] = useState(Cookies.get("banner") === encodedHide);

  if (hideBanner || isAdmin) return null;

  const varyingNotifications = [
    "Want to take incident response at your business to the next level?",
    "70% of the cost of an incident is outside security. Are you ready?",
    "Incident reporting and response requires a new paradigm.",
    "Looking to elevate your business's incident response?",
    "Considering a shift in your incident response strategy?",
    "Need an audit-ready, repeatable incident response process?",
    "Thinking about a proactive approach to incident response?",
  ];

  const varyingSmallNotifications = [
    "Take incident response to the next level",
    "Streamline and reduce incident costs",
    "Change your incident response approach",
    "Elevate your business's incident response",
    "Shift your incident response strategy",
    "Get an audit-ready, repeatable response",
    "Get proactively prepared for incidents",
  ];

  const whichNotification = random.int(0, varyingNotifications.length - 1);

  const platform = (
    <a
      href="https://www.breachrx.com/product/"
      target="_blank"
      rel="noopener noreferrer"
      className={classes.platformLink}
      onClick={() => emitMetric(`click banner link ${whichNotification}`)}
    >
      BreachRx Platform
    </a>
  );

  const notificationText =
    isSmallWindow ?
      <>
        {varyingSmallNotifications[whichNotification]} with the {platform}
      </> : <>
        {varyingNotifications[whichNotification]} Check out the {platform}. The
        smarter way to respond to incidents.
      </>;

  const setHideBannerCookie = () => {
    Cookies.set("banner", encodedHide, cookieAttributes(90));
    setHideBanner(true);
  };

  const CloseBannerIcon = () =>
    !isHighTier ? null : (
      <Close
        id="close-banner"
        aria-label="Close Banner"
        className={classes.closeIcon}
        onClick={() => setHideBannerCookie()}
        fontSize="small"
      />
    );

  return (
    <Box id="notification" className={classes.coreNotification}>
      <center>
        <Typography variant="caption" className={classes.bannerText}>
          {notificationText}
        </Typography>
        <CloseBannerIcon />
      </center>
    </Box>
  );
};

export default CoreBanner;
