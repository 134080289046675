import GenericNewsImage from "~/public/news.png";
import {isEmpty, isString} from "underscore";

export const whichImage = (news, theme) => {
  if (!isString(news.feed) || isEmpty(news.feed)) return GenericNewsImage;
  const feedName = news.feed;
  if (feedName.match(/BreachRx/i)) return theme.palette.logo;
  if (feedName.match(/The\s?Record/i)) return require("~/public/the-record.png");
  if (feedName.match(/iapp/i)) return require("~/public/iapp.svg");
  if (feedName.match(/Dark\s?Reading/i)) return require("~/public/darkreading.png");
  if (feedName.match(/Krebs\s?on\s?Security/i)) return require("~/public/krebs.png");
  if (feedName.match(/Law360/i)) return require("~/public/law360.png");
  if (feedName.match(/JD\s?Supra/i)) return require("~/public/jdsupra.png");
  if (feedName.match(/Cyberscoop|Fedscoop/i)) {
    return require("~/public/cyberscoop.png");
  }
  return GenericNewsImage;
};
