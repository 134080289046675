import {useEffect, useMemo, useState} from "react";

export const useTypewriter = (text, speed = 10) => {
  const [index, setIndex] = useState(0);
  const displayText = useMemo(() => text.slice(0, index), [index]);

  useEffect(() => {
    if (speed === 0) return;
    if (index >= text.length) return;
    const timeoutId = setTimeout(() => {
      setIndex(i => i + 1);
    }, speed);
    return () => clearTimeout(timeoutId);
  }, [index, text, speed]);

  if (speed === 0) return text;

  return displayText;
};
