import React from "react";
import {Box, Grid} from "@mui/material";
import {useRegulations} from "../contexts/RegulationsProvider";
import {useRegScoutCore} from "../../../resources/RegScoutCoreProvider";
import List from "./List";
import FloatingFilter from "../../../components/filters/FloatingFilter";
import MapFilterMenuPlatform from "../../../components/sidebar/menus/MapFilterMenuPlatform";
import RegulationMap from "./RegulationMap";

const Home = () => {
  const {mapOpen} = useRegulations();
  const {isPlatform} = useRegScoutCore();

  return (
    <Box display="flex" width={"100%"}>
      {isPlatform && (
        <>
          <FloatingFilter />
          <MapFilterMenuPlatform />
        </>
      )}
      <Box
        sx={{
          flexGrow: 1,
          bgcolor: "background.default",
          minHeight: "100vh",
        }}
        p={3}
      >
        {!isPlatform && <FloatingFilter />}
        <Grid
          container
          spacing={{lg: 2}}
          columns={{xs: 2, sm: 4, md: 4, lg: 8}}
        >
          {mapOpen ?
            <Box width={mapOpen ? "100%" : 800}>
              <RegulationMap />
            </Box> :
            <List />}
        </Grid>
      </Box>
    </Box>
  );
};

export default Home;
