import React from "react";
import {
  Box, List, ListItem, ListItemIcon, ListItemButton, ListItemText, Tooltip, Typography,
} from "@mui/material";
import GiveFeedback from "../dialogs/GiveFeedback";
import ProfileMenu from "../menus/ProfileMenu";
import FlagOutlinedIcon from "@mui/icons-material/FlagOutlined";
import MenuBookOutlinedIcon from "@mui/icons-material/MenuBookOutlined";
import {useNavigate} from "react-router";
import {useOverview} from "../../overview/OverviewProvider";
import {useRegulations} from "../../../pages/regulations/contexts/RegulationsProvider";
import {useAppControlsStyles} from "./appControlsStyles";

const AppControls = () => {
  const {sidebarOpen} = useRegulations();
  const {classes} = useAppControlsStyles({open: sidebarOpen});
  const {openOverview} = useOverview();
  const navigate = useNavigate();

  return (
    <Box py={.5}>
      <List sx={{padding: 0}}>
        <ListItem disablePadding>
          <Tooltip title="News Feed" placement="right">
            <ListItemButton onClick={() => navigate("/news")} className={classes.button}>
              <ListItemIcon><MenuBookOutlinedIcon /></ListItemIcon>
              <ListItemText id="news-feed">
                <Box className={classes.controlsItemTextWrapper}>
                  <Typography variant="body2">News Feed</Typography>
                </Box>
              </ListItemText>
            </ListItemButton>
          </Tooltip>
        </ListItem>
        <ListItem disablePadding>
          <GiveFeedback />
        </ListItem>
        <ListItem disablePadding>
          <Tooltip title="Regulation Overview" placement="right">
            <ListItemButton onClick={openOverview} className={classes.button}>
              <ListItemIcon><FlagOutlinedIcon /></ListItemIcon>
              <ListItemText id="overview">
                <Box className={classes.controlsItemTextWrapper}>
                  <Typography variant="body2">Regulation Overview</Typography>
                </Box>
              </ListItemText>
            </ListItemButton>
          </Tooltip>
        </ListItem>
        <ProfileMenu />
      </List>
    </Box>
  );
};

export default AppControls;
