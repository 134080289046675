import React from "react";
import PropTypes from "prop-types";
import {Fab, Tooltip} from "@mui/material";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import {makeStyles} from "tss-react/mui";

export const useBackArrowPlatformStyles = makeStyles()((theme) => ({
  backArrowPlatformButton: {
    position: "fixed",
    top: theme.spacing(18),
    left: theme.spacing(28),
    borderRadius: 0,
    width: "40px",
    height: "45px",
    [theme.breakpoints.down("sm")]: {
      top: theme.spacing(16),
      left: "0px",
      width: "30px",
    },
  },
}));

const BackArrowPlatform = ({onClick}) => {
  const {classes} = useBackArrowPlatformStyles();

  return (
    <>
      <Tooltip title="Back" placement="right">
        <Fab
          color="primary"
          aria-label="filters"
          className={classes.backArrowPlatformButton}
          onClick={() => onClick()}
        >
          <ArrowBackOutlinedIcon />
        </Fab>
      </Tooltip>
    </>
  );
};

BackArrowPlatform.propTypes = {
  onClick: PropTypes.func,
};

export default BackArrowPlatform;
