import React, {createContext, useContext} from "react";
import PropTypes from "prop-types";
import {isFunction} from "underscore";

const RegScoutCoreContext = createContext({
  emitMetric: () => {},
  emitStartStopMetric: () => {},
  useLogin: () => {},
});

export const RegScoutCoreProvider = ({children, useLogin, metrics}) => {
  const {
    login,
    loginSchema,
    user,
    setUser,
    currentPlanForDisplay,
    groups,
    isAdmin,
    isHighTier,
    isPaid,
    isPlatform,
    refreshSession,
    refreshError,
    setRefreshError,
    logout,
    isDarkThemeSaved,
    forgotPassword,
    resetPassword,
    isSubmitting,
    mfaChallenge,
    setMFAChallenge,
    mfaSchema,
    submitMfa,
    updateCustomerProfile,
    needsUpgrade,
    isProfessional,
  } = useLogin();

  const providerValues = {
    login: login,
    loginSchema: loginSchema,
    user: user,
    setUser: setUser,
    currentPlanForDisplay: currentPlanForDisplay,
    groups: groups,
    isAdmin: isAdmin,
    isHighTier: isHighTier,
    isPaid: isPaid,
    isPlatform: isPlatform,
    refreshSession: refreshSession,
    refreshError: refreshError,
    setRefreshError: setRefreshError,
    logout: logout,
    isDarkThemeSaved: isDarkThemeSaved,
    forgotPassword: forgotPassword,
    resetPassword: resetPassword,
    isSubmitting: isSubmitting,
    mfaChallenge: mfaChallenge,
    setMFAChallenge: setMFAChallenge,
    mfaSchema: mfaSchema,
    submitMfa: submitMfa,
    updateCustomerProfile: updateCustomerProfile,
    needsUpgrade,
    isProfessional,
    emitMetric: isFunction(metrics?.emitMetric) ? metrics.emitMetric : () => {},
    emitStartStopMetric:
      isFunction(metrics?.emitStartStopMetric) ?
        metrics.emitStartStopMetric : () => {},
  };

  return (
    <RegScoutCoreContext.Provider value={providerValues}>
      {children}
    </RegScoutCoreContext.Provider>
  );
};

RegScoutCoreProvider.propTypes = {
  children: PropTypes.any,
  useLogin: PropTypes.any,
  metrics: PropTypes.object,
};

export const useRegScoutCore = () => {
  return useContext(RegScoutCoreContext);
};
