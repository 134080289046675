import {gql} from "@apollo/client";

export const GET_USER = gql`
query GetUser($username: String!) {
  user(username: $username)
}`;

export const UPDATE_USER = gql`
mutation UpdateUser(
  $username: String!, 
  $customerName: String, 
  $orgFullName: String, 
  $orgTitle: String
  ) {
  updateUser(
    username: $username, 
    customerName: $customerName, 
    orgFullName: $orgFullName, 
    orgTitle: $orgTitle
  )
}`;

export const GET_VERSION = gql`
query GetVersion {
  version
}`;

export const GET_NEWS_SUBSCRIBER = gql`
query GetNewsSubscriber($username: String!) {
  newsSubscriber(username: $username)
}`;

export const UPDATE_NEWS_SUBSCRIBER = gql`
mutation UpdateNewsSubscriber($username: String!, $isSubscribing: Boolean!) {
  updateNewsSubscriber(username: $username, isSubscribing: $isSubscribing)
}`;
