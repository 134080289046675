import {makeStyles} from "tss-react/mui";
import {drawerWidth, smallDeviceWidth} from "../../resources/theme";

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

export const useSidebarStyles = makeStyles()((theme, {open}={}) => ({
  sidebarLogo: {
    backgroundImage: `url(${theme.palette.logo})`,
    height: 40,
    width: 182,
    [`@media (max-device-width: ${smallDeviceWidth})`]: {
      transform: "scale(1.15)",
    },
    display: "block",
    margin: "auto 0",
    [theme.breakpoints.down("sm")]: {
      transform: "scale(0.7)",
      marginLeft: theme.spacing(-4),
      padding: theme.spacing(0),
    },
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
  },
  sidebarShieldLogo: {
    backgroundImage: `url(${require("~/public/shield-logo.png")})`,
    height: 40,
    width: 35,
    [`@media (max-device-width: ${smallDeviceWidth})`]: {
      transform: "scale(1.2)",
    },
    display: "block",
    margin: "auto 0",
    [theme.breakpoints.down("sm")]: {
      transform: "scale(0.7)",
      marginLeft: theme.spacing(-4),
      padding: theme.spacing(0),
    },
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
  },
  sidebarLogoContainer: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: open ? "row" : "column",
    alignItems: "center",
    minHeight: 104,
    paddingTop: !open ? theme.spacing(1) : 0,
    paddingBottom: !open ? theme.spacing(1) : 0,
  },
  controlsItemTextWrapper: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  mainWrapper: {
    flexGrow: 1,
    overflowY: "auto",
    overflowX: "hidden",
    scrollbarWidth: "thin",
    scrollbarColor:
      `${theme.palette.layout.scrollbar.thumb} ${theme.palette.layout.scrollbar.track}`,
    "&::-webkit-scrollbar": {
      width: "0.4rem",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: theme.palette.layout.scrollbar.thumb,
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: theme.palette.layout.scrollbar.track,
    },
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
    boxSizing: "border-box",
    ...(open && {
      ...openedMixin(theme),
      "& .MuiDrawer-paper": openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      "& .MuiDrawer-paper": closedMixin(theme),
    }),
  },
}));

