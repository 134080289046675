import React, {useState} from "react";
import PropTypes from "prop-types";
import {Box, ListItemButton, ListItemText, Menu, Tooltip} from "@mui/material";
import {useRegulations} from "../../pages/regulations/contexts/RegulationsProvider";
import {map, isEmpty} from "underscore";
import {makeStyles} from "tss-react/mui";

export const useFilterStyles = makeStyles()((theme) => ({
  itemText: {
    "&> .MuiListItemText-secondary": {
      whiteSpace: "normal",
    },
  },
}));

const DynamicFilterMenuItem = ({children, filterType, menuLabel, tooltip, and=false}) => {
  const {currentFilters: regulationFilters, tagTypeData} = useRegulations();
  const {classes} = useFilterStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = !!anchorEl;

  const displayName = menuLabel ?? tagTypeData?.[filterType]?.displayName ?? filterType;
  const handleClose = () => {
    setAnchorEl(null);
  };

  const joinChar = and ? " & " : ", ";

  const subheading = () => {
    if (isEmpty(regulationFilters[filterType])) return "None Selected";
    if (["userInclude", "userExclude"].includes(filterType)) {
      return map(regulationFilters[filterType], ({regulator}) => regulator).join(joinChar);
    }
    if (filterType === "records") {
      return map(regulationFilters[filterType], (item) =>
        `${item.displayValue ?? item.value}: ${item.count === 0 ? "N/A" : item.count}`,
      ).join(joinChar);
    }
    return map(regulationFilters[filterType],
        (item) => item?.displayValue ?? item?.value,
    ).join(joinChar);
  };

  return (
    <>
      <ListItemButton
        id="regulation-filter-button"
        aria-haspopup="menu"
        aria-controls="filter-menu"
        aria-label="Filter Regulations"
        aria-expanded={open ? "true" : undefined}
        onClick={(event) => setAnchorEl(event.currentTarget)}
      >
        <Tooltip title={tooltip} placement="right">
          <ListItemText
            primary={displayName}
            secondary={subheading()}
            className={classes.itemText}
          />
        </Tooltip>
      </ListItemButton>
      <Menu
        id="regulation-filter-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{vertical: "top", horizontal: "left"}}
        PaperProps={{sx: {borderRadius: 0}}}
        MenuListProps={{"aria-labelledby": "filter-option-selector", role: "menu"}}
      >
        <Box sx={{
          minWidth: "500px",
          maxWidth: "600px",
          padding: 2,
        }}>
          {children}
        </Box>
      </Menu>
    </>
  );
};

DynamicFilterMenuItem.propTypes = {
  filterType: PropTypes.string.isRequired,
  menuLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  children: PropTypes.node.isRequired,
  multiple: PropTypes.bool,
  tooltip: PropTypes.string,
  and: PropTypes.bool,
};

export default DynamicFilterMenuItem;
