import React from "react";
import PropTypes from "prop-types";
import {
  Card, CardActionArea, CardContent, CardMedia, Typography, useTheme,
} from "@mui/material";
import dayjs from "dayjs";
import {whichImage} from "./newsHelpers";

const NewsCard = ({news, featured=false, classes, fullWidth=false}) => {
  const theme = useTheme();

  const maxHeadlineLength = 80;
  const title = news.title && news.title.length > maxHeadlineLength ?
    `${news.title.slice(0, maxHeadlineLength)}...` : news.title;
  const cleanContent = news.content && news.content.length > 0 ?
    `${news.content.replace(/<\/?("[^"]*"|'[^']*'|[^>])*(>|$)/g, "")}` : "";
  const overlay = featured ? "Featured" :
    news.date ? dayjs(news.date).format("ll") : false;

  return (
    <Card
      className={fullWidth ? `${classes.cardRoot} ${classes.fullWidth}` : classes.cardRoot}
      id="news"
    >
      <CardActionArea href={news.link} target="_blank" className={classes.action}>
        <CardMedia
          image={whichImage(news, theme)}
          className={classes.cardMedia}
          title={news.title}
        />
        {overlay ?
          <Typography
            variant="body1"
            className={featured ? `${classes.overlay} ${classes.featured}` : classes.overlay}
          >
            {overlay}
          </Typography> :
        ""}
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2" className={classes.title}>
            {title}
          </Typography>
          <Typography color="primary" component="h5" className={classes.feed}>
            {news.feed || ""}
          </Typography>
          <Typography
            variant="body2"
            color="textSecondary"
            component="p"
            className={classes.content}
          >
            {cleanContent}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

NewsCard.propTypes = {
  news: PropTypes.object.isRequired,
  featured: PropTypes.bool,
  classes: PropTypes.object.isRequired,
  fullWidth: PropTypes.bool,
};

export default NewsCard;
