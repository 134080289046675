import React, {useContext, createContext} from "react";
import PropTypes from "prop-types";
import {useParams} from "react-router";
import {useQuery} from "@apollo/client";
import {GET_REGULATION} from "../queries";
import {configureRegulation} from "../utils/dataTransformers";

export const DetailDataContext = createContext({
  data: {},
  loading: true,
});

export const DetailDataProvider = ({children}) => {
  const {id} = useParams();
  const {data, loading} = useQuery(GET_REGULATION, {
    variables: {id: parseInt(id, 10)},
  });

  return (
    <DetailDataContext.Provider value={{
      data: loading || !data ? {} : {...configureRegulation(data?.regulation, data?.tagTypes)},
      loading,
    }}>
      {children}
    </DetailDataContext.Provider>
  );
};

DetailDataProvider.propTypes = {
  children: PropTypes.any,
};

export const useDetailData = () => {
  return useContext(DetailDataContext);
};

