import React from "react";
import PropTypes from "prop-types";
import {Link} from "react-router-dom";
import {isEmpty} from "underscore";
import {useDetailStyles} from "./styles/detailStyles";
import dayjs from "dayjs";

const NewsItem = ({newsItem}) => {
  const {classes} = useDetailStyles();
  const {publishedAt, publisher, source, title} = newsItem;
  const breachRxItem = publisher === "BreachRx";

  const publisherToDisplay = isEmpty(publisher) || breachRxItem ? null : ` — ${newsItem.publisher}`;
  const publishedDateToDisplay = isEmpty(publishedAt) || breachRxItem ? null :
    (<> ({dayjs(publishedAt).format("ll")})</>);
  const sourceToDisplay = isEmpty(source) ? null :
    (<> | <Link className={classes.link} to={source} target="_blank">View</Link></>);

  return (
    <li id="news-item">
      {title}
      {publisherToDisplay}
      {publishedDateToDisplay}
      {sourceToDisplay}
    </li>
  );
};

NewsItem.propTypes = {
  newsItem: PropTypes.object,
};

export default NewsItem;
