import React from "react";
import {useFormContext} from "react-hook-form";
import {Box, InputAdornment, Stack, Switch, TextField, Typography} from "@mui/material";
import AccountCircleOutlined from "@mui/icons-material/AccountCircleOutlined";
import LockOutlined from "@mui/icons-material/LockOutlined";
import {yupResolver} from "@hookform/resolvers/yup";
import {BrowserRouter, Routes, Route, Link} from "react-router-dom";
import {useLogin} from "./LoginProvider";
import Register from "./Register";
import Validate from "./Validate";
import LoginContainer from "./LoginContainer";
import ResetPassword from "./ResetPassword";
import ForgotPassword from "./ForgotPassword";
import MFAChallengeForm from "./MFAChallengeForm";
import {isEmpty} from "underscore";
import {useLoginStyles} from "./LoginStyles";

export const Login = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/register" element={<Register />} />
        <Route path="/validate" element={<Validate />} />
        <Route path="/reset" element={<ResetPassword />} />
        <Route path="/forgot" element={<ForgotPassword />} />
        <Route path="/*" element={<LoginPage />} />
      </Routes>
    </BrowserRouter>
  );
};

const LoginPageContent = () => {
  const formMethods = useFormContext();
  const {isDarkThemeSaved} = useLogin();
  const {classes} = useLoginStyles({isDarkThemeSaved});

  return <>
    <TextField
      {...formMethods.register("username", {required: true})}
      id="username"
      label="Email"
      variant="outlined"
      size="medium"
      sx={{marginTop: 4}}
      InputLabelProps={{sx: {userSelect: "none"}}}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <AccountCircleOutlined className={classes.adornmentIcon} />
          </InputAdornment>
        ),
        sx: {borderRadius: 0},
      }}
      className={classes.loginTextField}
    />
    <TextField
      {...formMethods.register("password", {required: true})}
      type="password"
      id="password"
      label="Password"
      variant="outlined"
      size="medium"
      sx={{marginTop: 4}}
      InputLabelProps={{sx: {userSelect: "none"}}}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <LockOutlined className={classes.adornmentIcon} />
          </InputAdornment>
        ),
        sx: {borderRadius: 0},
      }}
      className={classes.loginTextField}
    />
    <Box display="flex" justifyContent="center" alignItems="center" marginTop={1}>
      <Switch
        {...formMethods.register("isMemorable")}
        id="isMemorable"
        defaultChecked
        className={classes.remember}
      />
      <Typography variant="caption" fontSize="1rem" className={classes.rememberText}>
        Remember me
      </Typography>
    </Box>
    <Box marginTop={1} marginBottom={1}>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={1}
      >
        <Typography variant="caption" fontSize="1rem" className={classes.rememberText}>
          <Link to="/register" style={{color: "#42a8d2", textDecoration: "none"}}>Register</Link>
        </Typography>
        <Typography variant="caption" fontSize="1rem" className={classes.rememberText}>
          <Link to="/forgot" style={{color: "#42a8d2", textDecoration: "none"}}>
          Forgot Password?
          </Link>
        </Typography>
      </Stack>
    </Box>
  </>;
};

export const LoginPage = () => {
  const {login, loginSchema, mfaChallenge} = useLogin();

  if (!isEmpty(mfaChallenge)) return <MFAChallengeForm />;

  return (
    <LoginContainer
      submit={login}
      title="Login to Cyber RegScout&trade;"
      submitText="Login"
      formResolver={yupResolver(loginSchema)}
      successMessage="Login successful..."
    >
      <LoginPageContent />
    </LoginContainer>
  );
};
