import {makeStyles} from "tss-react/mui";

const smallDeviceWidth = "768px";

export const useLoginStyles = makeStyles()((theme, {isDarkThemeSaved}={}) => ({
  loginPaper: {
    minHeight: theme.spacing(34),
    minWidth: theme.spacing(55),
    padding: "30px 40px",
    [`@media (max-device-width: ${smallDeviceWidth})`]: {
      minHeight: theme.spacing(75),
      minWidth: theme.spacing(75),
      padding: "70px 70px",
    },
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    background: isDarkThemeSaved ? "#212121" : undefined,
  },
  closeIconBox: {
    background: isDarkThemeSaved ? "#212121" : undefined,
  },
  closeIcon: {
    marginLeft: `calc(100% - ${theme.spacing(4)})`,
    marginTop: theme.spacing(0.5),
    [`@media (max-device-width: ${smallDeviceWidth})`]: {
      marginTop: theme.spacing(1),
      height: "2em",
      width: "2em",
      marginLeft: `calc(100% - ${theme.spacing(7)})`,
    },
  },
  logo: {
    height: 43,
    width: 192,
    transform: "scale(1.2)",
    [`@media (max-device-width: ${smallDeviceWidth})`]: {
      transform: "scale(3.0)",
      marginTop: theme.spacing(2),
    },
    margin: "auto 0",
    backgroundImage: isDarkThemeSaved ?
      `url(${require("~/public/logo-dark.png")})` :
      `url(${require("~/public/logo-light.png")})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
  },
  confirmationText: {
    color: isDarkThemeSaved ? "rgb(199, 199, 199)" : "rgb(33, 33, 33)",
    textAlign: "center",
    maxWidth: 400,
  },
  loginMessages: {
    fontSize: "1rem",
    [`@media (max-device-width: ${smallDeviceWidth})`]: {
      fontSize: "2rem",
    },
  },
  loginTextField: {
    [`@media (max-device-width: ${smallDeviceWidth})`]: {
      minWidth: "70vw",
    },
    "& label": {
      color: isDarkThemeSaved ? "rgb(199, 199, 199)" : "rgb(33, 33, 33)",
      [`@media (max-device-width: ${smallDeviceWidth})`]: {
        fontSize: "2.15rem",
        lineHeight: "70%",
        padding: theme.spacing(0.25),
        marginLeft: theme.spacing(-0.5),
      },
      "&.Mui-focused": {
        color: "#42a8d2 !important",
      },
      "&.Mui-disabled": {
        color: isDarkThemeSaved ? "rgb(199, 199, 199, 0.4)" : "rgb(33, 33, 33, 0.4)",
      },
    },
    "& div.MuiOutlinedInput-root": {
      [`@media (max-device-width: ${smallDeviceWidth})`]: {
        fontSize: "2rem",
        marginBottom: theme.spacing(2.5),
      },
      fieldset: {
        borderColor: isDarkThemeSaved ? "rgba(255, 255, 255, 0.23)" : undefined,
      },
      "&.Mui-disabled div.MuiInputAdornment-positionStart": {
        opacity: 0.4,
      },
      "&.Mui-disabled fieldset": {
        borderColor: isDarkThemeSaved ? "rgba(255, 255, 255, 0.23)" : undefined,
      },
      ":hover fieldset": {
        borderColor: isDarkThemeSaved ? "rgb(221, 221, 221)" : undefined,
      },
      "&.Mui-focused": {
        fieldset: {
          borderColor: "#42a8d2 !important",
        },
        input: {
          boxShadow: isDarkThemeSaved ? "#212121 0px 0px 0px 100px inset" : undefined,
          WebkitTextFillColor: isDarkThemeSaved ? "rgb(221, 221, 221)" : undefined,
        },
      },
      "& div.MuiInputAdornment-positionStart": {
        color: isDarkThemeSaved ? "rgb(199, 199, 199)" : undefined,
      },
      input: {
        color: isDarkThemeSaved ? "rgb(221, 221, 221)" : undefined,
        [`@media (max-device-width: ${smallDeviceWidth})`]: {
          height: "1.75em",
        },
        ":-internal-autofill-selected": {
          boxShadow: isDarkThemeSaved ?
            "#212121 0px 0px 0px 100px inset" : "#ffffff 0px 0px 0px 100px inset",
          WebkitTextFillColor: isDarkThemeSaved ? "rgb(221, 221, 221)" : undefined,
        },
        "&.Mui-disabled": {
          opacity: isDarkThemeSaved ? 0.6 : 0.8,
        },
      },
    },
  },
  adornmentIcon: {
    [`@media (max-device-width: ${smallDeviceWidth})`]: {
      width: "2em",
      height: "2em",
    },
  },
  remember: {
    [`@media (max-device-width: ${smallDeviceWidth})`]: {
      transform: "scale(1.75)",
      marginTop: theme.spacing(1),
      marginRight: theme.spacing(4),
    },
    ".Mui-checked": {
      color: "#42a8d2 !important",
    },
    ".MuiSwitch-track": {
      backgroundColor: isDarkThemeSaved ? "rgb(221, 221, 221)" : undefined,
    },
    ".Mui-checked + .MuiSwitch-track": {
      backgroundColor: "#42a8d2 !important",
    },
  },
  rememberText: {
    color: isDarkThemeSaved ? "rgb(199, 199, 199)" : undefined,
    [`@media (max-device-width: ${smallDeviceWidth})`]: {
      fontSize: "2.5rem",
      marginTop: theme.spacing(1),
    },
  },
  returnToLogin: {
    color: "#42a8d2",
  },
  submit: {
    borderColor: "#42a8d2",
    borderRadius: 0,
    color: "#42a8d2",
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    [`@media (max-device-width: ${smallDeviceWidth})`]: {
      fontSize: "2rem !important",
      marginTop: theme.spacing(4),
      lineHeight: 2,
    },
    "&:hover": {
      backgroundColor: "#42a8d2 !important",
      borderColor: "#42a8d2 !important",
      color: isDarkThemeSaved ? "#212121" : "#ffffff",
    },
    "&.Mui-disabled": {
      color: "#42a8d270",
      border: "1px solid #42a8d240",
    },
  },
  tagline: {
    color: "gray",
    fontFamily: "Oswald, sans-serif",
    marginTop: theme.spacing(2),
    [`@media (max-device-width: ${smallDeviceWidth})`]: {
      marginTop: theme.spacing(9),
      marginBottom: theme.spacing(3),
      fontSize: "3rem",
    },
  },
  verifiedText: {
    color: isDarkThemeSaved ? "rgb(199, 199, 199)" : "rgb(33, 33, 33)",
    [`@media (max-device-width: ${smallDeviceWidth})`]: {
      fontSize: "1.75rem",
    },
  },
  wrapper: {
    display: "flex",
    flexGrow: 1,
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    [`@media (max-device-width: ${smallDeviceWidth})`]: {
      height: "unset",
      paddingTop: "5%",
    },
    background: isDarkThemeSaved ? "#212121" : undefined,
  },
}));
