import React from "react";
import PropTypes from "prop-types";
import {Box, Skeleton} from "@mui/material";

export const UserProfileDetailsSkeleton = ({padding=3}) => {
  return (
    <Box p={padding} width="100%" id="user-profile-details-skeleton">
      <Skeleton variant="text" width={250} height={60} />
      <Box mt={1}>
        <Skeleton variant="rectangular" width="100%" height={56} sx={{marginBottom: 1.5}} />
        <Skeleton variant="rectangular" width="100%" height={56} sx={{marginBottom: 1.5}} />
        <Skeleton variant="rectangular" width="100%" height={56} sx={{marginBottom: 2}} />
        <Skeleton variant="rectangular" width={88} height={40} />
      </Box>
    </Box>
  );
};

UserProfileDetailsSkeleton.propTypes = {
  padding: PropTypes.number,
};
