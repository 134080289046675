import {
  contains,
  each,
  every,
  filter,
  find,
  findWhere,
  groupBy,
  includes,
  isArray,
  isEmpty,
  isNaN,
  isUndefined,
  map,
  pluck,
  reject,
  some,
  sortBy,
  values,
} from "underscore";
import DOMPurify from "dompurify";
import dayjs from "dayjs";
import {riskScoreDefinition} from "../../../resources/environment";
import {isEmptyHTML} from "../../../shared/stringUtils";

const allFiltersEmptyOrNull = (selectedfilters) => {
  return every(values(selectedfilters), (filterValues) => {
    if (isArray(filterValues)) return isEmpty(filterValues);
    return filterValues ? filterValues.value === null : true;
  });
};

const regMatchesAnySelectedTag = (tagValues, regulation) => {
  const regulationTagValues = map(regulation.tags, (regTag) => regTag.tag.value);
  return some(tagValues, (tagValue) => contains(regulationTagValues, tagValue));
};

export const filterRegulations = (regData, selectedFilters) => {
  if (!regData) return [];
  if (allFiltersEmptyOrNull(selectedFilters)) return regData;

  return filter(regData, (reg) => {
    const {records, regulationTypes, userInclude, userExclude, ...tagFilters} = selectedFilters;

    if (!isEmpty(userInclude) && map(userInclude, ({id})=> id).includes(reg.id)) return true;
    if (!isEmpty(userExclude) && map(userExclude, ({id})=> id).includes(reg.id)) return false;

    if (!isEmpty(regulationTypes) && !includes(pluck(regulationTypes, "id"), reg.type?.id)) {
      return false;
    }

    for (const filterValues of values(tagFilters)) {
      if (isEmpty(filterValues)) continue;
      const values = isArray(filterValues) ?
        map(filterValues, ({value}) => value) : [filterValues.value];
      if (!regMatchesAnySelectedTag(values, reg)) {
        return false;
      }
    }

    if (!records || isEmpty(records)) return true;

    const geoTags = filter(reg.tags, (regTag) => regTag.tag.type.name === "Geography");
    const geoValues = map(geoTags, ({tag}) => {
      const parsedValue = JSON.parse(tag.value);
      return parsedValue?.country?.name ?? parsedValue?.state?.name;
    });
    const thresholdTags = filter(reg.tags, ({tag}) => tag.type.name === "NotificationThreshold");

    for (const record of records) {
      const regMatchesGeoRecord = geoValues.includes(record.value);
      const recordCount = parseInt(record.count, 10);

      if (recordCount > 0 && regMatchesGeoRecord) {
        if (isEmpty(thresholdTags)) return true;

        const thresholdValues = map(thresholdTags, ({tag}) => parseInt(JSON.parse(tag.value), 10));
        const minThresholdValue = Math.min(...thresholdValues);

        if (recordCount < minThresholdValue) return false;
      }
    }
    return true;
  });
};

export const getMinutesFromDeadlineTag = (tag, includeInitialInterval=false) => {
  const deadline = JSON.parse(tag.value);
  if (!deadline.duration) return NaN;
  const duration = {[deadline.duration.interval]: deadline.duration.length};
  if (includeInitialInterval) {
    return {interval: deadline.duration.interval, minutes: dayjs.duration(duration).asMinutes()};
  };
  return dayjs.duration(duration).asMinutes();
};

const sortRegulations = (data, sortType) => {
  let sortedData;
  const type = sortType.split("_")[0];
  const direction = sortType.split("_")[1];

  switch (type) {
    case "deadline":
      sortedData = sortBy(data, ({tags}) => {
        const deadlineTags = filter(tags, ({tag}) => tag.type.name === "Deadline");
        if (isEmpty(deadlineTags)) {
          return dayjs.duration({hours: 20000}).asMinutes(); // no deadline tags = longest length;
        }
        if (deadlineTags.length > 1) {
          const tagMinutes = [];

          for (const {tag} of deadlineTags) {
            const mins = getMinutesFromDeadlineTag(tag);
            if (!isNaN(mins)) tagMinutes.push(mins);
          }
          if (!isEmpty(tagMinutes)) return Math.min(...tagMinutes);

          // only has string deadline tags
          return dayjs.duration({hours: 10000}).asMinutes(); // longest of all computed durations
        }

        const minutes = getMinutesFromDeadlineTag(deadlineTags[0].tag);
        if (isNaN(minutes)) return dayjs.duration({hours: 10000}).asMinutes();
        return minutes;
      });
      break;
    case "risk":
      sortedData = sortBy(data, (regulation) => {
        const riskTag = find(regulation.tags, ({tag}) => tag.type.name === "RiskScore");
        if (riskTag?.tag?.value) return parseInt(JSON.parse(riskTag.tag.value), 10);
      });
      break;
    case "regulator":
      sortedData = sortBy(data, type);
      break;
    case "updated":
      sortedData = sortBy(data, "updatedAt");
      break;
    case "effective":
      sortedData = sortBy(data, "effectiveAt");
      break;
    case "type":
      sortedData = sortBy(data, (regulation) => [regulation.type.name, regulation.regulator]);
      break;
    default:
      sortedData = data;
  }

  const orderedData = direction === "asc" ? sortedData : sortedData.reverse();
  return orderedData;
};

export const sortAndCombineRegulations = (matchedRegulations, sortType, allRegulations) => {
  const sortedMatchedRegulations = sortRegulations(matchedRegulations, sortType);
  const sortedMatchedRegulationIds = pluck(sortedMatchedRegulations, "id");
  const remainingRegulations = filter(allRegulations, ({id}) =>
    !sortedMatchedRegulationIds.includes(id));
  const remainingTaggedRegulations = map(remainingRegulations, (regulation) => {
    return {...regulation, filtered: true};
  });

  return [...sortedMatchedRegulations, ...remainingTaggedRegulations];
};

export const generateFilterOptions = (regs) => {
  if (!isArray(regs)) return null;
  const uniqueTags = [];
  for (const {tags} of regs) {
    for (const {tag} of tags) {
      const parsedValue = JSON.parse(tag.value);
      let displayValue = undefined;
      let isCountry = true;
      if (tag?.type?.name === "Geography") {
        displayValue = parsedValue?.country?.name ?? parsedValue?.state?.name;
        if (!parsedValue?.country?.name) isCountry = false;
      } else if (tag?.type?.typeName === "String") {
        displayValue = parsedValue;
      };
      if (!findWhere(uniqueTags, {id: tag.id})) uniqueTags.push({...tag, displayValue, isCountry});
    }
  }

  return groupBy(uniqueTags, (tag) => tag?.type?.name ?? "unsorted");
};

export const generateTagTypeData = (filterOptions) => {
  const tagTypeData = {};
  each(filterOptions, (values, tagType) => {
    tagTypeData[tagType] = values[0].type;
  });
  return tagTypeData;
};

export const getDeadlineString = (deadline) => {
  if (isEmpty(deadline)) return "";
  if (deadline.duration) {
    const length = deadline.duration.length;
    const interval = deadline.duration.interval;
    const intervalToDisplay = parseInt(length, 10) === 1 ? interval.slice(0, -1) : interval;
    const duration = `${length} ${intervalToDisplay}`;
    if (!deadline.description || isEmpty(deadline.description)) return duration;

    return `${duration} - ${deadline.description}`;
  }
  return deadline.description;
};

export const getDeadlineDisplay = (tags) => {
  const deadlineTags = filter(tags, ({tag}) => tag.type.name === "Deadline");

  if (isEmpty(deadlineTags)) {
    return "Deadline: N/A";
  }
  if (deadlineTags.length > 1) {
    const parsedDeadlines = [];
    const deadlinesWithNoIntervals = [];

    for (const {tag} of deadlineTags) {
      const deadline = getMinutesFromDeadlineTag(tag, true);
      if (isFinite(deadline.minutes)) parsedDeadlines.push(deadline);
      else deadlinesWithNoIntervals.push(JSON.parse(tag.value));
    }

    if (!isEmpty(parsedDeadlines)) {
      const shortestDeadlineInMinutes = {minutes: Math.min(...pluck(parsedDeadlines, "minutes"))};
      const shortestDeadline = findWhere(parsedDeadlines, shortestDeadlineInMinutes);
      const interval = shortestDeadline.interval;
      const length = dayjs.duration(shortestDeadlineInMinutes).as(interval);
      const deadlineToDisplay = {duration: {length, interval}};
      return `Deadline: ${getDeadlineString(deadlineToDisplay)}`;
    }

    const descriptionToUse = findWhere(deadlinesWithNoIntervals, {notificationType: "REGULATOR"}) ??
      deadlinesWithNoIntervals?.[0];
    const deadlineDescription = !isEmptyHTML(descriptionToUse?.description) ?
      descriptionToUse.description: "ASAP";
    return `Deadline: ${deadlineDescription}`;
  }

  const deadlineTag = JSON.parse(deadlineTags[0].tag.value);
  return `Deadline: ${getDeadlineString(deadlineTag)}`;
};

export const generateDescriptionDisplay = (regulation) => {
  if (isEmpty(regulation.description)) {
    return `The ${regulation.regulator} regulation was passed on
      ${dayjs(regulation.legislationPassedAt).format("LL")} and has been effective
      since ${dayjs(regulation.effectiveAt).format("LL")}.`;
  };

  return DOMPurify.sanitize(regulation.description, {ALLOWED_TAGS: ["p"]});
};

export const getNotificationDeadlines = (tags) => {
  const deadlines = filter(tags, ({tag}) => tag.type.name === "Deadline");

  const notificationDeadlinesByType = {};

  for (const {tag: {value}} of deadlines) {
    const parsedDeadline = JSON.parse(value);
    if (!parsedDeadline?.notificationType) continue;
    notificationDeadlinesByType[parsedDeadline.notificationType] = parsedDeadline;
  }

  return notificationDeadlinesByType;
};

export const computeRiskChip = (riskTag) => {
  if (!riskTag || !riskTag?.tag?.value) {
    return {color: "primary", label: "Unknown Risk", tooltip: riskScoreDefinition, type: "risk"};
  }
  let riskScore = JSON.parse(riskTag.tag.value);
  let risk;

  if (isNaN(parseInt(riskScore, 10))) {
    switch (riskScore.toLowerCase()) {
      case "high":
        risk = {color: "error", label: "High Risk"};
        break;
      case "medium":
        risk = {color: "warning", label: "Medium Risk"};
        break;
      case "low":
        risk = {color: "success", label: "Low Risk"};
        break;
      default:
        risk = {color: "default", label: "Unknown Risk"};
    }
  } else {
    riskScore = parseInt(riskScore, 10);
    if (riskScore >= 80) risk = {color: "error", label: `Risk: ${riskScore}`};
    else if (riskScore < 80 && riskScore > 49) {
      risk = {color: "warning", label: `Risk: ${riskScore}`};
    } else if (riskScore <= 49) risk = {color: "success", label: `Risk: ${riskScore}`};
  }

  return {...risk, tooltip: riskScoreDefinition, type: "risk"};
};

export const getTypeLabel = (regulationType) => {
  if (regulationType === "DataBreach") return "Data Breach";
  return regulationType;
};

const getTypeChip = (regulationType) => {
  let fill;
  let typeColor;
  let unknownType;

  switch (regulationType) {
    case "DataBreach":
      fill = "#a54dca";
      break;
    case "Cybersecurity":
      fill = "#8a8b8c";
      break;
    case "Privacy":
      typeColor = "secondary";
      break;
    default:
      typeColor = "primary";
      unknownType = true;
      break;
  }

  const label = getTypeLabel(regulationType);
  const otherTypes = reject(["cybersecurity", "data breach", "privacy"], (type) =>
    type === label.toLowerCase(),
  );
  const tooltip = unknownType ? "" :
    `A ${label.toLowerCase()} regulation, as opposed to a ${otherTypes[0]}
      or ${otherTypes[1]} regulation.`;

  return {color: typeColor, fill, label, tooltip, type: "type"};
};

export const generateChips = (regulation) => {
  const tags = regulation.tags;
  const riskTag = find(tags, ({tag}) => tag.type.name === "RiskScore");
  const regulationType = regulation.type.name;

  return [
    computeRiskChip(riskTag),
    getTypeChip(regulationType),
  ];
};

export const filterIncludeExcludes = (filters, include=false) => {
  const filterName = include ? "userInclude" : "userExclude";
  const oppositeFilterName = include ? "userExclude": "userInclude";
  const filterIds = map(filters[filterName], ({id}) => id);
  const newExcludeFilters =
      filter(filters[oppositeFilterName], ({id}) => !filterIds.includes(id));
  return {...filters, [oppositeFilterName]: newExcludeFilters};
};

export const notificationTypeDisplay = {
  REGULATOR: "Regulator",
  CONSUMER: "Consumer",
  CREDIT_RATING_AGENCY: "Consumer Reporting Agencies",
  SUBSTITUTE_NOTICE: "Substitute Notice",
  OTHER: "Other",
};

export const extractGeography = (regulation) => {
  if (!regulation) return;
  const rawGeographyTags = filter(regulation.tags, (each) => each.tag.type?.name === "Geography");
  return map(rawGeographyTags, (geographyTag) => {
    const geographyTags = JSON.parse(geographyTag.tag.value);
    return {
      ...(geographyTags?.country ?? geographyTags?.state),
      type: geographyTags.geographyType,
      regulationId: regulation.id,
    };
  });
};

export const addToRiskScoreColors = (riskScoreObject, riskScoreColor, isoCode) => {
  if (riskScoreObject?.[isoCode]) riskScoreObject[isoCode].push(riskScoreColor);
  else riskScoreObject[isoCode] = [riskScoreColor];
};

export const riskScoreColorForCount = (countryRiskScoreColors) => {
  if (isEmpty(countryRiskScoreColors)) return "primary";
  const riskScoreForCountry = sortBy(countryRiskScoreColors, (color) => (
    ["primary", "success", "warning", "error"].indexOf(color)
  ));
  const combinedHighestRiskScoreColor = riskScoreForCountry[riskScoreForCountry.length - 1];
  return combinedHighestRiskScoreColor;
};

export const matchFiltersToGeographies = (filteredGeographyList, geographyList) => {
  const finalMatches = [];
  each(filteredGeographyList, (filteredGeography) => {
    const match = find(geographyList, (geography) => {
      return (
        !isUndefined(geography?.name) && !isUndefined(filteredGeography?.name) &&
        !isUndefined(geography?.type) && !isUndefined(filteredGeography?.type) &&
        geography.name === filteredGeography.name && geography.type === filteredGeography.type
      );
    });
    if (match) finalMatches.push(match);
  });
  return finalMatches;
};

export const configureRegulation = (regulation, tagTypes) => {
  const workingRegulation = {...regulation};
  const workingTags = [];

  for (const tag of regulation.tags ?? []) {
    const tagType = findWhere(tagTypes, {id: tag.tag.typeId});
    workingTags.push({id: tag.id, tag: {...tag.tag, type: tagType}});
  };
  delete workingRegulation.tags;
  workingRegulation.tags = workingTags;

  return ({
    ...workingRegulation,
    deadlineDisplay: getDeadlineDisplay(workingRegulation.tags),
    descriptionDisplay: generateDescriptionDisplay(workingRegulation),
    geography: extractGeography(workingRegulation),
  });
};
