import React from "react";
import {Button} from "@mui/material";
import {useNavigate} from "react-router-dom";
import {useLoginStyles} from "./LoginStyles";

const ReturnToLogin = () => {
  const navigate = useNavigate();
  const {classes} = useLoginStyles();

  return (
    <Button
      onClick={() => navigate("/")}
      className={classes.returnToLogin}
      id="return-to-login"
    >
      Return To Login
    </Button>
  );
};

export default ReturnToLogin;
