import React, {useState} from "react";
import PropTypes from "prop-types";
import LoginContainer from "./LoginContainer";
import {Typography, TextField, InputAdornment} from "@mui/material";
import AccountCircleOutlined from "@mui/icons-material/AccountCircleOutlined";
import {useFormContext} from "react-hook-form";
import * as yup from "yup";
import {isBoolean} from "underscore";
import {useLogin} from "./LoginProvider";
import {useLoginStyles} from "./LoginStyles";

const ForgotPassword = () => {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isSuccess, setIsSuccess] = useState();
  const {forgotPassword, isDarkThemeSaved} = useLogin();
  const {classes} = useLoginStyles({isDarkThemeSaved});

  const submit = async (data, formMethods) => {
    const emailSchema = yup.object({username: yup.string().email()});
    const isValidEmail = await emailSchema.isValid(data);
    if (!isValidEmail) {
      formMethods.setError("username", {type: "manual", message: "Invalid email address."});
      return;
    };
    setIsSubmitted(true);
    formMethods.setError("username", {type: "manual", message: ""});
    await forgotPassword(data, formMethods, setIsSuccess);
  };

  const successMessage = (
    <Typography sx={{marginTop: 2, marginBottom: 2}} className={classes.confirmationText}>
      Thank you for submitting your password reset request.
      An email with further instructions has been sent to your registered email address.
    </Typography>
  );

  return (
    <LoginContainer
      submit={submit}
      title="Forgot Password"
      submitText={isBoolean(isSuccess) ? undefined : "Reset Password"}
      disableSubmit={isSubmitted}
      showReturnToLogin={isBoolean(isSuccess)}
    >
      <ForgotPasswordFormContent disabled={isSubmitted} />
      {isSuccess ? successMessage : null}
    </LoginContainer>
  );
};

const ForgotPasswordFormContent = ({disabled=false}) => {
  const {isDarkThemeSaved} = useLogin();
  const {classes} = useLoginStyles({isDarkThemeSaved});
  const formMethods = useFormContext();

  return <TextField
    {...formMethods.register("username", {required: true})}
    id="username"
    label="Email"
    variant="outlined"
    size="medium"
    sx={{marginTop: 4, marginBottom: 1}}
    disabled={disabled}
    InputLabelProps={{sx: {userSelect: "none"}}}
    InputProps={{
      startAdornment: (
        <InputAdornment position="start">
          <AccountCircleOutlined className={classes.adornmentIcon} />
        </InputAdornment>
      ),
      sx: {borderRadius: 0},
    }}
    className={classes.loginTextField}
  />;
};

ForgotPasswordFormContent.propTypes = {
  disabled: PropTypes.bool,
};

export default ForgotPassword;
