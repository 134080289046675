import React from "react";
import {Box, Skeleton} from "@mui/material";
import {map} from "underscore";

export const SkeletonNews = () => (
  <Box width="100%" sx={(theme) => ({backgroundColor: theme.palette.background.default})}>
    <Box p={3} id="skeleton-news" data-testid="skeleton-news">
      <Skeleton variant="text" height={40} width={80} />
      <Box mt={1} mx={5}>
        <Skeleton variant="rectangular" sx={{minWidth: "100%", minHeight: 350}} />
        <Box mx={1} px={2}>
          {map(Array(5), (each, index) => (
            <Skeleton
              variant="rectangular"
              key={index}
              sx={{marginTop: 3, minWidth: "100%", minHeight: 100}}
            />
          ))}
        </Box>
      </Box>
    </Box>
  </Box>
);
